import {
  CautionDispatcher,
  ProvisionCity,
  SheetData,
  SheetsDispatcher,
  SiblingSheetData,
  SiblingSheetsDispatcher,
} from "../../types"
import { UpperLimitManagementListItem } from "./components/UpperLimitManagementListItem"
import { UpperSiblingLimitManagementListItem } from "./components/UpperSiblingLimitManagementListItem"

type ProvisionCityHeaderProps = {
  name: string
  number: string
}

function ProvisionCityHeader({ name, number }: ProvisionCityHeaderProps) {
  return (
    <h3
      className={"provision-city-header"}
      style={{ marginBottom: 16 }}
    >{`${name} (${number})`}</h3>
  )
}

type Props = {
  provisionCities: ProvisionCity[]
  displayMunicipality: boolean
  sheets: SheetData
  siblingSheets: SiblingSheetData
  setSheets: SheetsDispatcher
  setSiblingSheets: SiblingSheetsDispatcher
  setCaution: CautionDispatcher
}

export function UpperLimitManagementList({
  provisionCities,
  displayMunicipality,
  sheets,
  siblingSheets,
  setSheets,
  setSiblingSheets,
  setCaution,
}: Props) {
  return (
    <div className={"table-section"}>
      {provisionCities.map(
        ({
          municipality_number,
          provision_city_name,
          receipt_upper_limit_managements,
          receipt_sibling_upper_limit_managements,
        }) => (
          <div key={municipality_number}>
            {displayMunicipality && (
              <ProvisionCityHeader
                key={municipality_number}
                name={provision_city_name}
                number={municipality_number}
              />
            )}
            {/* 通常の上限管理 */}
            {receipt_upper_limit_managements.map(
              (receiptUpperLimitManagement) => (
                <UpperLimitManagementListItem
                  key={receiptUpperLimitManagement.id}
                  receiptUpperLimitManagement={receiptUpperLimitManagement}
                  sheets={sheets}
                  setSheets={setSheets}
                  setCaution={setCaution}
                  style={{ marginBottom: 24 }}
                />
              )
            )}
            {/* 兄弟の上限管理 */}
            {receipt_sibling_upper_limit_managements.map(
              (receiptSiblingUpperLimitManagement) => (
                <UpperSiblingLimitManagementListItem
                  key={receiptSiblingUpperLimitManagement.id}
                  receiptSiblingUpperLimitManagement={
                    receiptSiblingUpperLimitManagement
                  }
                  siblingSheets={siblingSheets}
                  setSiblingSheets={setSiblingSheets}
                  setCaution={setCaution}
                />
              )
            )}
          </div>
        )
      )}
    </div>
  )
}
