import { API } from "@project/shared"

const BASE_URL = "/parent-self-assessments/aggregation"

export const fetchSelfAssessmentAggregation = async (
  year: number,
  facilityId: number,
  serviceType: number
) => {
  return API.get(
    `${BASE_URL}/${facilityId}/${year}?service_type=${serviceType}`
  )
}
