import {
  CALANDER_HOURS_MINUTES,
  CHILD_SUPPORT_ATTACH_TABLE_DAY_ORDER,
  Checkboxes,
  MinuteOptionsAtInterval,
  SelectInput,
  Table,
  theme,
  timeDifference,
} from "@project/shared"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { OwnerButton } from "../../atoms"
import { useMemo, useState } from "react"
import TextArea from "antd/lib/input/TextArea"
import {
  GetCategoryByNumberOfMinutes,
  IsAfterSchoolChild,
} from "../../../utils/attendance"

const AttachTableContainer = styled.div`
  width: 100%;
  .attach-title {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    font-weight: bold;
    background: ${theme.gray};
  }
  .bulk-input {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    background: ${theme.lightorange};
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    .bulk-label {
      font-weight: bold;
    }
    .ant-checkbox-group {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      label {
        margin-bottom: 0px;
      }
    }
  }
  .time-container {
    display: flex;
    gap: 5px;
    align-items: center;
    flex-wrap: wrap;
  }
  .center-content {
    display: flex;
    justify-content: center;
    gap: 5px;
    flex-wrap: wrap;
  }
  table {
    th,
    .highlight-cell {
      background: ${theme.gray};
    }
    .ant-table-cell {
      padding: 16px 8px;
    }
  }
`

export interface IDayTimePeriod {
  start_hour: string
  start_minute: string
  end_hour: string
  end_minute: string
}

export interface IDayObj {
  mon?: IDayTimePeriod
  tue?: IDayTimePeriod
  wed?: IDayTimePeriod
  thu?: IDayTimePeriod
  fri?: IDayTimePeriod
  sat?: IDayTimePeriod
  sun?: IDayTimePeriod
}

export interface IAttachTable {
  offer_time: IDayObj
  notice: string
  before_offer_time: IDayObj
  after_provision_time: IDayObj
  reason: string
}

interface IChildSupportAttachTable {
  attachTableData: IAttachTable
  updateAttachTableData: (val) => void
  serviceType: number
}

export const ChildSupportAttachTable = (props: IChildSupportAttachTable) => {
  const { t } = useTranslation()
  const { attachTableData, updateAttachTableData, serviceType } = props
  const attachTableColumns = [
    {
      title: "",
      dataIndex: "title",
      align: "center",
      className: "highlight-cell",
    },
    {
      title: t("Mon"),
      dataIndex: "mon",
      align: "center",
      onCell: (row) => {
        return {
          colSpan: row?.combineAllDays ? 7 : 1,
        }
      },
    },
    {
      title: t("Tue"),
      dataIndex: "tue",
      align: "center",
      onCell: (row) => {
        return {
          colSpan: row?.combineAllDays ? 0 : 1,
        }
      },
    },
    {
      title: t("Wed"),
      dataIndex: "wed",
      align: "center",
      onCell: (row) => {
        return {
          colSpan: row?.combineAllDays ? 0 : 1,
        }
      },
    },
    {
      title: t("Thu"),
      dataIndex: "thu",
      align: "center",
      onCell: (row) => {
        return {
          colSpan: row?.combineAllDays ? 0 : 1,
        }
      },
    },
    {
      title: t("Fri"),
      dataIndex: "fri",
      align: "center",
      onCell: (row) => {
        return {
          colSpan: row?.combineAllDays ? 0 : 1,
        }
      },
    },
    {
      title: t("Sat"),
      dataIndex: "sat",
      align: "center",
      onCell: (row) => {
        return {
          colSpan: row?.combineAllDays ? 0 : 1,
        }
      },
    },
    {
      title: t("Sunday and holidays"),
      dataIndex: "sun",
      align: "center",
      onCell: (row) => {
        return {
          colSpan: row?.combineAllDays ? 0 : 1,
        }
      },
    },
  ]

  const minuteOptions = MinuteOptionsAtInterval(5)
  const hourOptions = CALANDER_HOURS_MINUTES.hours

  const [bulkSetting, setBulkSetting] = useState({
    selectedDays: [],
    selectedStartHour: "",
    selectedStartMinute: "",
    selectedEndHour: "",
    selectedEndMinute: "",
  })

  const dayOrder = CHILD_SUPPORT_ATTACH_TABLE_DAY_ORDER

  const applyBatchUpdate = () => {
    updateAttachTableData((prev) => {
      const newOfferTimes = { ...prev.offer_time }
      bulkSetting?.selectedDays?.forEach((day) => {
        if (typeof newOfferTimes[day] != "object") {
          newOfferTimes[day] = {}
        }
        if (bulkSetting.selectedStartHour) {
          newOfferTimes[day].start_hour = bulkSetting.selectedStartHour
        }
        if (bulkSetting.selectedStartMinute) {
          newOfferTimes[day].start_minute = bulkSetting.selectedStartMinute
        }
        if (bulkSetting.selectedEndHour) {
          newOfferTimes[day].end_hour = bulkSetting.selectedEndHour
        }
        if (bulkSetting.selectedEndMinute) {
          newOfferTimes[day].end_minute = bulkSetting.selectedEndMinute
        }
      })
      return {
        ...prev,
        offer_time: newOfferTimes,
      }
    })
  }

  const renderDuration = (
    startHour,
    startMinute,
    endHour,
    endMinute,
    service,
    fop
  ) => {
    const difference = timeDifference(
      `${startHour}:${startMinute}`,
      `${endHour}:${endMinute}`
    )
    if (difference.error) return
    const category = GetCategoryByNumberOfMinutes(
      difference.totalMinute,
      IsAfterSchoolChild(service, fop)
    )
    return <strong>{category}</strong>
  }

  const handleTimeChange = ({ day, type, field, value }) => {
    updateAttachTableData((previousState) => {
      const prev = { ...previousState }
      if (typeof prev?.[type] != "object") {
        prev[type] = {}
      }

      if (typeof prev?.[type]?.[day] != "object") {
        prev[type][day] = {}
      }

      prev[type][day][field] = value

      if (field == "start_hour") {
        if (!value) {
          prev[type][day].start_minute = ""
        } else if (!prev[type][day]?.start_minute) {
          prev[type][day].start_minute = "00"
        }
      } else if (field == "end_hour") {
        if (!value) {
          prev[type][day].end_minute = ""
        } else if (!prev[type][day]?.end_minute) {
          prev[type][day].end_minute = "00"
        }
      }
      return prev
    })
  }

  const renderTimeSelectionForDay = ({
    dayKey = "",
    type = "",
    startHour = "",
    startMinute = "",
    endHour = "",
    endMinute = "",
    showDuration = false,
    form_of_provision = 1,
  }) => {
    return (
      <div className={"center-content"}>
        <div className={"time-container"}>
          <SelectInput
            options={hourOptions}
            value={startHour}
            placeholder={"--"}
            onChange={(val) =>
              handleTimeChange({
                day: dayKey,
                type,
                field: "start_hour",
                value: val,
              })
            }
          />
          {" : "}
          <SelectInput
            options={minuteOptions}
            value={startMinute}
            placeholder={"--"}
            onChange={(val) =>
              handleTimeChange({
                day: dayKey,
                type,
                field: "start_minute",
                value: val,
              })
            }
          />
        </div>
        {" ~ "}
        <div className={"time-container"}>
          <SelectInput
            options={hourOptions}
            value={endHour}
            placeholder={"--"}
            onChange={(val) =>
              handleTimeChange({
                day: dayKey,
                type,
                field: "end_hour",
                value: val,
              })
            }
          />
          {" : "}
          <SelectInput
            options={minuteOptions}
            value={endMinute}
            placeholder={"--"}
            onChange={(val) =>
              handleTimeChange({
                day: dayKey,
                type,
                field: "end_minute",
                value: val,
              })
            }
          />
        </div>
        {showDuration && (
          <div>
            {t("Support time division")}&nbsp;
            {renderDuration(
              startHour,
              startMinute,
              endHour,
              endMinute,
              serviceType,
              form_of_provision
            )}
          </div>
        )}
      </div>
    )
  }

  const renderTextArea = ({ key, value, placeholder }) => {
    return (
      <TextArea
        value={value}
        placeholder={placeholder}
        onChange={(e) =>
          updateAttachTableData((prev) => ({
            ...prev,
            [key]: e?.target?.value,
          }))
        }
      />
    )
  }

  const tableDataSource = useMemo(() => {
    // first row is offer time
    const firstRow: any = {
      title: t("Offer time"),
    }

    // second row is for notice
    const secondRow = {
      title: t("Notices"),
      mon: renderTextArea({
        value: attachTableData.notice,
        key: "notice",
        placeholder: t(
          "Example: If the usage time increases due to the convenience of users, daycare centers, schools, etc., or long holidays (spring break, summer vacation, etc.), the planned number of hours should be set to 5 hours."
        ),
      }),
      combineAllDays: true,
    }

    // third row is for before offer time
    const thirdRow: any = {
      title: (
        <>
          {t("[Before the offer time]")}
          <br />
          {t("Extended support time")}
        </>
      ),
    }

    // fourth row is after provision time
    const fourthRow: any = {
      title: (
        <>
          {t("[After the provision time]")}
          <br />
          {t("Extended support time")}
        </>
      ),
    }

    // fifth row if for reason
    const fifthRow: any = {
      title: t("Reason and time required for extension"),
      mon: renderTextArea({
        value: attachTableData.reason,
        key: "reason",
        placeholder: t(
          "Example: During the busy season (March) at the parents' workplaces, it is expected that there will be days when the extended support time will be 2 hours after the support provided on Mondays, Wednesdays, and Fridays. Provide extended support as necessary."
        ),
      }),
      combineAllDays: true,
    }

    dayOrder.forEach((day) => {
      firstRow[day] = renderTimeSelectionForDay({
        dayKey: day,
        type: "offer_time",
        startHour: attachTableData.offer_time?.[day]?.start_hour,
        startMinute: attachTableData.offer_time?.[day]?.start_minute,
        endHour: attachTableData.offer_time?.[day]?.end_hour,
        endMinute: attachTableData.offer_time?.[day]?.end_minute,
        showDuration: true,
        form_of_provision: day == "sun" || day == "sat" ? 2 : 1, // school holiday for sat & sun else after school
      })
      thirdRow[day] = renderTimeSelectionForDay({
        dayKey: day,
        type: "before_offer_time",
        startHour: attachTableData.before_offer_time?.[day]?.start_hour,
        startMinute: attachTableData.before_offer_time?.[day]?.start_minute,
        endHour: attachTableData.before_offer_time?.[day]?.end_hour,
        endMinute: attachTableData.before_offer_time?.[day]?.end_minute,
        showDuration: false,
      })
      fourthRow[day] = renderTimeSelectionForDay({
        dayKey: day,
        type: "after_provision_time",
        startHour: attachTableData.after_provision_time?.[day]?.start_hour,
        startMinute: attachTableData.after_provision_time?.[day]?.start_minute,
        endHour: attachTableData.after_provision_time?.[day]?.end_hour,
        endMinute: attachTableData.after_provision_time?.[day]?.end_minute,
        showDuration: false,
      })
    })

    return [firstRow, secondRow, thirdRow, fourthRow, fifthRow]
  }, [attachTableData])

  return (
    <AttachTableContainer>
      <div className={"attach-title"}>
        {t("Individual support plan attached table")}
      </div>
      <div className={"bulk-input"}>
        <div className={"bulk-label"}>{t("Bulk registration")}</div>
        <Checkboxes
          options={[
            {
              label: t("Mon"),
              value: "mon",
            },
            {
              label: t("Tue"),
              value: "tue",
            },
            {
              label: t("Wed"),
              value: "wed",
            },
            {
              label: t("Thu"),
              value: "thu",
            },
            {
              label: t("Fri"),
              value: "fri",
            },
            {
              label: t("Sat"),
              value: "sat",
            },
            {
              label: t("Sunday and holidays"),
              value: "sun",
            },
          ]}
          value={bulkSetting.selectedDays}
          onChange={(val) =>
            setBulkSetting((prev) => ({ ...prev, selectedDays: val }))
          }
        />
        <div className={"time-container"}>
          <SelectInput
            options={hourOptions}
            value={bulkSetting.selectedStartHour}
            placeholder={"--"}
            onChange={(val) =>
              setBulkSetting((prev) => ({
                ...prev,
                selectedStartHour: val,
                selectedStartMinute: !val
                  ? null
                  : !bulkSetting.selectedStartMinute
                  ? "00"
                  : bulkSetting.selectedStartMinute,
              }))
            }
          />
          {" : "}
          <SelectInput
            options={minuteOptions}
            value={bulkSetting.selectedStartMinute}
            placeholder={"--"}
            onChange={(val) =>
              setBulkSetting((prev) => ({
                ...prev,
                selectedStartMinute: val,
              }))
            }
          />
          {" ~ "}
          <SelectInput
            options={hourOptions}
            value={bulkSetting.selectedEndHour}
            placeholder={"--"}
            onChange={(val) =>
              setBulkSetting((prev) => ({
                ...prev,
                selectedEndHour: val,
                selectedEndMinute: !val
                  ? null
                  : !bulkSetting.selectedEndMinute
                  ? "00"
                  : bulkSetting.selectedEndMinute,
              }))
            }
          />
          {" : "}
          <SelectInput
            options={minuteOptions}
            value={bulkSetting.selectedEndMinute}
            placeholder={"--"}
            onChange={(val) =>
              setBulkSetting((prev) => ({
                ...prev,
                selectedEndMinute: val,
              }))
            }
          />
        </div>
        <OwnerButton
          typeOf={"secondary"}
          text={t("Batch input")}
          onClick={applyBatchUpdate}
        />
      </div>
      <Table
        columns={attachTableColumns}
        dataSource={tableDataSource}
        hover={false}
        bordered
      />
    </AttachTableContainer>
  )
}
