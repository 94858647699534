// packages
import moment from "moment"

// styled components
import {
  FacilityChildListContainer,
  NoticeWrapper,
  PrintButtonContainer,
} from "../styles"

// components
import { OwnerButton } from "../../../atoms"
import { TopRoundedWhiteCard } from "@project/shared"
import { ChangeDisplayBox, InfoBox, NoticeBox, PrimaryActionButtons } from "."

// utils
import { Divisor } from "../../../../utils"

// types
import {
  FacilityChildListBlockProps,
  SheetData,
  ProvisionCity,
  SiblingSheetData,
} from "../types"

import { useRouter } from "next/router"
import { Spin } from "antd"
import { useTranslation } from "react-i18next"
import { useMemo, useRef, useState } from "react"
import { useReactToPrint } from "react-to-print"
import { callMaximumResultOutputSlip } from "../apiAdapter"
import { InfoBoxMainText } from "./InfoVoxMainText"
import { UpperLimitManagementList } from "./UpperLimitManagementList"
import { OneChildSheet } from "./sheet/OneChildSheet"
import { SiblingChildSheet } from "./sheet/SiblingChildSheet"
import { PrintSheet } from "./sheet/SiblingChildSheet/print.style"

export const FacilityChildListBlock2024: React.FC<
  FacilityChildListBlockProps
> = ({
  currentDate,
  handleCSVDownload,
  currentBillingMethodName,
  isBillingInfoLoading,
  currentSearchQueries,
  isCSVDownloading = false,
  isUserFeeConfirmed = false,
  togglePrintPage,
  isPrintPage = false,
  printFilter,
  saveUpperLimitDivision,
  isSavingUpperLimitDivision,
}) => {
  const { t } = useTranslation()

  const router = useRouter()
  const { after_school } = router.query

  const [displayMunicipality, setDisplayByMunicipality] = useState(true)
  // const [maximumOutputSlip, setMaximumOutputSlip] = useState({
  //   output: [],
  //   siblings: [],
  //   parentIdsWithSiblings: [],
  //   upperLimitManagedChild: [],
  //   upperLimitManagedChildIds: [],
  // })
  const maximumOutputSlip = {
    output: [],
    siblings: [],
    parentIdsWithSiblings: [],
    upperLimitManagedChild: [],
    upperLimitManagedChildIds: [],
  }

  const [provisionCities, setProvisionCities] = useState<ProvisionCity[]>([])

  const [sheets, setSheets] = useState<SheetData>({})
  const [siblingSheets, setSiblingSheets] = useState<SiblingSheetData>({})
  const [caution, setCaution] = useState<string>(null)

  // const selectedRef = useMemo(() => {
  //   const selectedChilds = []
  //   maximumOutputSlip?.output?.forEach((o) => {
  //     o?.child_data?.forEach((p) => {
  //       if (p?.selected) {
  //         p?.child_maximum_result?.forEach((c) => {
  //           if (selectedChilds?.indexOf(c?.child_id) == -1) {
  //             selectedChilds.push(c.child_id)
  //           }
  //         })
  //       }
  //     })
  //   })
  //   return selectedChilds
  // }, [maximumOutputSlip])

  // Get ReceiptUpperLimitManagement Data
  const { isLoading } = callMaximumResultOutputSlip(
    currentSearchQueries,
    Number(after_school),
    setProvisionCities,
    setSheets,
    setSiblingSheets
  )

  function handleCheckAll(checked: boolean) {
    setSheets((prev) => {
      Object.keys(prev).forEach((k) => {
        prev[k].checked = checked
      })
      return { ...prev }
    })

    setSiblingSheets((prev) => {
      Object.keys(prev).forEach((k) => {
        prev[k].checked = checked
      })
      return { ...prev }
    })
  }

  function handleCheckedAllExceptSibling() {
    setSheets((prev) => {
      Object.keys(prev).forEach((k) => {
        prev[k].checked = true
      })
      return { ...prev }
    })
  }

  // const handleCheckAllExceptSibling = () => {
  //   const newOutput = maximumOutputSlip.output?.map((op) => {
  //     const newChildData = op?.child_data.map((p) => {
  //       const selection = !maximumOutputSlip?.parentIdsWithSiblings?.includes(
  //         p?.parent_id?.toString()
  //       )
  //       return {
  //         ...p,
  //         selected: selection,
  //         child_maximum_result: p?.child_maximum_result?.map((cmr) => {
  //           return { ...cmr, selected: selection }
  //         }),
  //       }
  //     })
  //     return { ...op, child_data: newChildData }
  //   })

  //   setMaximumOutputSlip((prev) => {
  //     return { ...prev, output: newOutput }
  //   })
  // }

  const handleTemporarySave = () => {
    // save to db not temporary
    const payloadData: Array<{
      id: number
      sibling_upper_limit: boolean
      information_creation_category: number
    }> = []
    for (const key in sheets) {
      if (sheets[key]?.checked) {
        const data = sheets[key]?.receiptUpperLimitManagement
        payloadData.push({
          id: data?.id,
          sibling_upper_limit: false,
          information_creation_category: data?.information_creation_category,
        })
      }
    }

    for (const key in siblingSheets) {
      if (siblingSheets[key]?.checked) {
        const data = siblingSheets[key]?.receiptSiblingUpperLimitManagement
        payloadData.push({
          id: data?.id,
          sibling_upper_limit: true,
          information_creation_category: data?.information_creation_category,
        })
      }
    }

    if (payloadData.length > 0) {
      saveUpperLimitDivision(payloadData)
      setCaution("")
    }
  }

  const outputCount = useMemo(() => {
    const sheetCount = Object.values(sheets).filter((s) => s.checked).length
    const siblignSheetCount = Object.values(siblingSheets).filter(
      (s) => s.checked
    ).length

    return sheetCount + siblignSheetCount
  }, [sheets, siblingSheets])

  const renderNoticeBox = () =>
    maximumOutputSlip?.upperLimitManagedChild?.length > 0 ? (
      <NoticeBox siblings={maximumOutputSlip?.upperLimitManagedChild} />
    ) : (
      <></>
    )
  const printContentRef = useRef<HTMLDivElement>(null)
  const onPrint = useReactToPrint({
    content: () => printContentRef?.current,
  })

  function isPrintBtnDisable() {
    return (
      [...Object.values(sheets), ...Object.values(siblingSheets)].filter(
        ({ checked }) => checked
      ).length === 0
    )
  }

  function startCSVDownload(csvFor: number) {
    const childIds: number[] = []

    Object.values(sheets).forEach((sheet) => {
      childIds.push(sheet.receiptUpperLimitManagement.child_id)
    })

    Object.values(siblingSheets).forEach((sheet) => {
      childIds.push(sheet.receiptSiblingUpperLimitManagement.child_id)
    })

    handleCSVDownload(csvFor, childIds.join(","), "")
  }

  function disableCSVButton() {
    // disabled
    if (!isUserFeeConfirmed) {
      return true
    }

    return Object.values(sheets).filter(({ checked }) => checked).length === 0
  }

  return (
    <>
      {!isPrintPage ? (
        <FacilityChildListContainer>
          <TopRoundedWhiteCard
            className={"operation-rounded-container"}
            title={t("{{currEngDate}} Facility use children list", {
              currEngDate: moment(currentDate).format("MMM, YYYY"),
              currJaDate: moment(currentDate).format("YYYY年MM月"),
            })}
          >
            {/* Upper notice section starts here */}
            {!isBillingInfoLoading && (
              <div className={"upper-notice-section no-print"}>
                <InfoBox
                  href={`/national-health-insurance-billing?facility_id=${currentSearchQueries?.facility}&year=${currentSearchQueries?.year}&month=${currentSearchQueries?.month}&change_billing=1`}
                  linkText={"Change billing information"}
                >
                  <InfoBoxMainText billingMethod={currentBillingMethodName} />
                </InfoBox>
                <ChangeDisplayBox
                  isDisplayByMunicipality={displayMunicipality}
                  setDisplayByMunicipality={(val) =>
                    setDisplayByMunicipality(val)
                  }
                />
                {renderNoticeBox()}
                {!isUserFeeConfirmed && <UnconfirmedAlert />}
              </div>
            )}
            {/* Upper notice section ends here */}

            <Divisor className={"divider no-print"} />
            {isLoading ? (
              <div className={"spin-container"}>
                <Spin size={"large"} />
              </div>
            ) : (
              <>
                {/* upper-mid multi-functional buttons section starts here */}
                <div className={"multi-functional-btns-section no-print"}>
                  <PrimaryActionButtons
                    handlePrint={togglePrintPage}
                    handleCSVDownload={startCSVDownload}
                    isCSVDownloading={isCSVDownloading}
                    outputCount={outputCount}
                    disableOutputBtn={disableCSVButton()}
                    disablePrintBtn={isPrintBtnDisable()}
                    handleTemporarySave={handleTemporarySave}
                    isSaving={isSavingUpperLimitDivision}
                    cautionMsg={caution}
                  />
                  <div className={"secondary-action-btn-group flexbox"}>
                    {/* すべてチェック */}
                    <OwnerButton
                      text={t("Check all")}
                      typeOf={"check-all"}
                      onClick={() => handleCheckAll(true)}
                    />
                    {/* 兄弟以外のすべてにチェック */}
                    <OwnerButton
                      text={t("Check all except siblings")}
                      typeOf={"check-all"}
                      onClick={() => handleCheckedAllExceptSibling()}
                    />
                    {/* クリア */}
                    <OwnerButton
                      text={t("Clear")}
                      typeOf={"outlined"}
                      onClick={() => handleCheckAll(false)}
                    />
                  </div>
                </div>
                {/* upper-mid multi-functional buttons section ends here */}

                {/* mid table section starts here - ja: 上限管理結果 一覧 */}
                <UpperLimitManagementList
                  provisionCities={provisionCities}
                  displayMunicipality={displayMunicipality}
                  sheets={sheets}
                  siblingSheets={siblingSheets}
                  setSheets={setSheets}
                  setSiblingSheets={setSiblingSheets}
                  setCaution={setCaution}
                />
                {/* mid table section ends here */}

                {/* Lower footer section starts here */}
                <div className={"lower-footer-section no-print"}>
                  {renderNoticeBox()}
                  <PrimaryActionButtons
                    handlePrint={togglePrintPage}
                    hasExtraBtn
                    disableOutputBtn={disableCSVButton()}
                    disablePrintBtn={isPrintBtnDisable()}
                    handleCSVDownload={startCSVDownload}
                    isCSVDownloading={isCSVDownloading}
                    outputCount={outputCount}
                    handleTemporarySave={handleTemporarySave}
                    isSaving={isSavingUpperLimitDivision}
                    cautionMsg={caution}
                  />
                </div>
                {/* Lower footer section ends here */}
              </>
            )}
          </TopRoundedWhiteCard>
        </FacilityChildListContainer>
      ) : (
        <>
          <PrintButtonContainer>
            <OwnerButton
              typeOf={"secondary"}
              text={t("Cancel")}
              onClick={togglePrintPage}
            />
            <OwnerButton
              typeOf={"print"}
              text={t("Print")}
              onClick={onPrint}
              className={"print-button"}
            />
          </PrintButtonContainer>
          <div
            ref={printContentRef}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {Object.values(sheets)
              .filter((sheet) => sheet.checked)
              .map((sheet) => (
                <PrintSheet key={sheet.receiptUpperLimitManagement.id}>
                  <OneChildSheet
                    sheet={sheet}
                    printStyle={printFilter.style}
                    printConfig={printFilter.configuration}
                  />
                </PrintSheet>
              ))}

            {Object.values(siblingSheets)
              .filter((sheet) => sheet.checked)
              .map((sheet) => (
                <PrintSheet key={sheet.receiptSiblingUpperLimitManagement.id}>
                  <SiblingChildSheet
                    sheet={sheet}
                    printStyle={printFilter.style}
                    printConfig={printFilter.configuration}
                  />
                </PrintSheet>
              ))}
          </div>
        </>
      )}
    </>
  )
}

function UnconfirmedAlert() {
  return (
    <NoticeWrapper className={"common-box-container"}>
      <div className={`notice-content`}>
        {/* notice main title/btn starts here */}
        <p
          className={"notice-content_title"}
          style={{ display: "flex", alignItems: "center", gap: 16 }}
        >
          <span className={"alert-icon"}>
            <img
              src={"/assets/icons/NotificationAlert.svg"}
              alt={"alter icon"}
            />
          </span>
          <span className={"actual-notice-text"}>
            {"請求が確定していないため、CSVデータの出力はできません。"}
            <br />
            {
              "出力する場合は「国保連請求-帳票・CSV出力」の画面から請求を確定させてください。"
            }
          </span>
        </p>
        {/* notice main title/btn ends here */}
      </div>
    </NoticeWrapper>
  )
}
