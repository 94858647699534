import { Checkbox, theme } from "@project/shared"
import { Input, Popconfirm } from "antd"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Button } from "../../atoms"
import {
  AttendanceScheduleTimeRangeInput,
  AttendanceTypeButtons,
} from "../../molecules"

const Wrapper = styled.div`
  background: #f5f5f5;
  border: 1px solid #d2d1d1;
  border-radius: 12px;
  padding: 4px 8px;
  width: auto;
  max-width: 100%;
  .shift-select {
    display: flex;
    justify-content: center;
  }
  .record-container {
    margin: 10px 0;
  }
  .inner-record-container {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
    div {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .record-button {
    button {
      padding: 10px !important;
    }
  }
  .break-time-container {
    margin: 10px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
    input {
      max-width: 40px;
    }

    /* stylelint-disable property-no-vendor-prefix */
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    .break-input-container {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
  .content {
    display: flex;
    align-items: center;
    gap: 4px;
    .ant-typography {
      margin-bottom: 0;
    }
    .inputs {
      display: flex;
      flex-direction: row;
      .ant-select {
        width: 65px;
      }
    }
  }
  .button-container {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .save-reset-buttons {
    display: flex;
    justify-content: flex-end;
    .reset-button {
      padding-left: 10px;
      text-decoration: underline;
    }
  }
  &.invalid-attendance {
    border-color: ${theme.red2};
    .record-title {
      color: ${theme.red2};
    }
  }
`

const AbsentWrapper = styled.div`
  color: #e40102;
  text-align: center;
  padding: 0 10px;
`

interface IRecordButton {
  type: "remove" | "add"
}

export type AttendanceHours = {
  id: number
  attendanceType: number
  from: {
    hours: string
    minutes: string
  }
  to: {
    hours: string
    minutes: string
  }
  break_time: number
}

const QuickAttendanceRecordUpdateForm = ({ recordDetail, onSave }) => {
  const { t } = useTranslation()
  const initialValues = {
    attendanceType: null,
    from: {
      hours: null,
      minutes: null,
    },
    to: {
      hours: null,
      minutes: null,
    },
    break_time: null,
  }

  const possibleRecordIds = [1, 2]

  const getHours = () => {
    if (!recordDetail?.id)
      return [
        {
          id: 1,
          ...initialValues,
        },
      ]
    const shiftHours = []
    const prefix = "attendance_"
    possibleRecordIds.map((id) => {
      const postfix = id > 1 ? id : ""
      const attendanceKey = prefix + "type" + postfix
      const attendanceType = recordDetail[attendanceKey]
      if (!attendanceType) return
      const startTimeKey = prefix + "start_time" + postfix
      const startTimes = recordDetail[startTimeKey]?.split(":")
      const endTimeKey = prefix + "end_time" + postfix
      const endTimes = recordDetail[endTimeKey]?.split(":")
      const breakTimeKey = prefix + "rest_minits" + postfix
      const breakTime = recordDetail[breakTimeKey]
      shiftHours.push({
        id,
        attendanceType: attendanceType ? attendanceType : 0,
        from: {
          hours: startTimes ? startTimes[0] : null,
          minutes: startTimes ? startTimes[1] : null,
        },
        to: {
          hours: endTimes ? endTimes[0] : null,
          minutes: endTimes ? endTimes[1] : null,
        },
        break_time: breakTime,
      })
    })
    return shiftHours
  }

  const [restTime, setRestTime] = useState({
    is_rest: recordDetail?.rest_minits_flg ?? 0,
    rest_hours: recordDetail?.rest_hours ?? null,
  })
  const [shiftHours, setShiftHours] = useState<Array<AttendanceHours>>(
    getHours()
  )
  const [isInvalid, setInvalid] = useState(false)

  const onTimeRangeChange = ({ id, range, unit, value }: any) => {
    const data = [...shiftHours]
    const index = data?.findIndex((x) => x?.id === id)
    if (range == "break_time") {
      data[index] = {
        ...data[index],
        [range]: value,
      }
    } else {
      data[index] = {
        ...data[index],
        [range]: {
          ...data[index][range],
          [unit]: value,
        },
      }
    }
    setShiftHours(data)
  }

  const addNewRecord = () => {
    setShiftHours([
      ...shiftHours,
      { id: shiftHours?.length + 1, ...initialValues },
    ])
  }
  const removeLastRecord = () => {
    const data = shiftHours?.splice(-1)[0]
    setShiftHours(shiftHours?.filter((v) => v.id !== data.id))
  }

  const RecordButton = ({ type }: IRecordButton) => {
    const { t } = useTranslation()
    return (
      <div className={"record-button"}>
        <Button
          text={
            type == "add" ? t("Add working hours") : t("Remove working hours")
          }
          shape={"circle"}
          borderColor={theme.gray2}
          backgroundColor={"#ffffff"}
          icon={type == "add" ? "add" : "close"}
          onClick={() => {
            type == "add" ? addNewRecord() : removeLastRecord()
          }}
        />
      </div>
    )
  }

  const changeAttendanceType = (id, attendanceType) => {
    const data = [...shiftHours]
    const index = data?.findIndex((x) => x?.id === id)
    data[index] = {
      ...data[index],
      attendanceType,
    }
    setShiftHours(data)
  }

  const isValid = () => {
    const timeObj = {
      startInMinute1: 0,
      endInMinute1: 0,
      restInMinute1: 0,
      startInMinute2: 0,
      endInMinute2: 0,
      restInMinute2: 0,
    }

    let somethingMissing = false
    let isAbsent = false

    if (restTime.is_rest == 1 && restTime.rest_hours > 0) {
      // allow to save when rest time is checked
      return true
    }

    shiftHours.map((shiftHour) => {
      if (shiftHour.id == 1) isAbsent = shiftHour.attendanceType == 2
      timeObj[`startInMinute${shiftHour.id}`] =
        +shiftHour.from.hours * 60 + +shiftHour.from.minutes
      timeObj[`endInMinute${shiftHour.id}`] =
        +shiftHour.to.hours * 60 + +shiftHour.to.minutes
      timeObj[`restInMinute${shiftHour.id}`] = +shiftHour.break_time

      if (
        (!shiftHour.from.hours ||
          !shiftHour.from.minutes ||
          !shiftHour.to.hours ||
          !shiftHour.to.minutes) &&
        shiftHour.attendanceType != 2
      )
        somethingMissing = true
    })

    if (isAbsent) return true

    const netMinute =
      timeObj.endInMinute1 -
      timeObj.startInMinute1 -
      timeObj.restInMinute1 +
      (timeObj.endInMinute2 - timeObj.startInMinute2 - timeObj.restInMinute2)

    if (netMinute <= 0 || somethingMissing) return false
    return true
  }

  const handleSave = () => {
    if (!isValid()) {
      setInvalid(true)
      return
    }
    setInvalid(false)
    const attendanceData: any = {
      staff_id: recordDetail.staff_id,
      facility_id: recordDetail.facility_id,
      date: recordDetail.date,
      remarks: recordDetail?.remarks ?? null,
      rest_minits_flg: restTime?.is_rest,
      rest_hours: restTime?.rest_hours,
    }
    const prefix = "attendance_"
    shiftHours.map((shiftHour) => {
      const postfix = shiftHour.id > 1 ? shiftHour.id : ""

      const attendanceTypeKey = prefix + "type" + postfix
      attendanceData[attendanceTypeKey] = shiftHour.attendanceType

      const startTimeKey = prefix + "start_time" + postfix
      attendanceData[startTimeKey] =
        shiftHour.attendanceType == 1
          ? (shiftHour.from.hours ?? "00") +
            ":" +
            (shiftHour.from.minutes ?? "00")
          : null

      const endTimeKey = prefix + "end_time" + postfix
      attendanceData[endTimeKey] =
        shiftHour.attendanceType == 1
          ? (shiftHour.to.hours ?? "00") + ":" + (shiftHour.to.minutes ?? "00")
          : null

      const breakTimeKey = prefix + "rest_minits" + postfix
      attendanceData[breakTimeKey] = +shiftHour.break_time
    })

    if (recordDetail?.id) attendanceData.id = recordDetail.id
    onSave(attendanceData)
  }

  const handleReset = () => {
    const attendanceData = {
      id: recordDetail?.id,
      is_delete: true,
    }
    onSave(attendanceData)
    setShiftHours([{ ...initialValues, id: 1 }])
  }

  return (
    <Wrapper className={isInvalid ? "invalid-attendance" : ""}>
      <div className={"break-time-container"}>
        <Checkbox
          name={"break-time"}
          label={t("Break time")}
          checked={restTime.is_rest == 1}
          onClick={() => {
            const isSet = !restTime.is_rest ? 1 : 0
            setRestTime({
              ...restTime,
              is_rest: isSet,
              rest_hours: isSet ? restTime?.rest_hours : 0,
            })
          }}
        />
        <div className={"break-input-container"}>
          <Input
            type={"number"}
            min={0}
            width={10}
            placeholder={t("Eg: 8")}
            value={restTime.rest_hours}
            onChange={(e) => {
              setRestTime({
                ...restTime,
                rest_hours: e.target.value ? +e.target.value : 0,
              })
            }}
            disabled={restTime.is_rest != 1}
          />
          {t("Hrs")}
        </div>
      </div>
      <div className={"record-container"}>
        {shiftHours?.map((range, index) => (
          <div key={index} className={"inner-record-container"}>
            <div className={"record-title"}>{`${t("Working hours")} ${
              range?.id
            }`}</div>
            {range.attendanceType == 1 ? (
              <AttendanceScheduleTimeRangeInput
                key={range?.id}
                range={range}
                rangeName={""}
                handleChange={(val) => {
                  onTimeRangeChange(val)
                }}
              />
            ) : range.attendanceType == 2 ? (
              <AbsentWrapper>{t("Absence")}</AbsentWrapper>
            ) : (
              <AttendanceTypeButtons
                onChange={(val) => {
                  changeAttendanceType(range?.id, val)
                }}
              />
            )}
          </div>
        ))}
        <div className={"button-container"}>
          {shiftHours?.length > 0 && shiftHours[0].attendanceType == 1 && (
            <RecordButton
              type={
                shiftHours.length == possibleRecordIds.length ? "remove" : "add"
              }
            />
          )}
          {((shiftHours?.length > 0 && shiftHours[0].attendanceType) ||
            restTime.is_rest == 1) && (
            <div className={"save-reset-buttons"}>
              <Button
                text={t("Save")}
                backgroundColor={theme.blue5}
                color={"#ffffff"}
                shape={"circle"}
                onClick={handleSave}
              />
              {recordDetail?.id && (
                <Popconfirm
                  title={t("Confirm reset ?")}
                  placement={"bottom"}
                  okText={t("Yes")}
                  cancelText={t("No")}
                  onConfirm={handleReset}
                >
                  <Button text={t("Reset")} className={"reset-button"} />
                </Popconfirm>
              )}
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  )
}

export { QuickAttendanceRecordUpdateForm }
