import Link from "next/link"
import React, { useState } from "react"

// packages
import { t } from "i18next"
import router from "next/router"

// styled components
import {
  CautionInfo,
  InfoBoxWrapper,
  NoticeWrapper,
  OutputButton,
} from "../styles"

// components
import { OwnerButton } from "../../../atoms"

// types
import { PrimaryActionButtonsProps } from "../types"

// assets
import RedDownArrow from "../../../../public/assets/icons/RedDownArrow"
import { Spin } from "antd"

export const BackBtn = ({ link }) => {
  return (
    <div className={"middle-back-btn no-print"}>
      <OwnerButton
        onClick={() => {
          router.push(link)
        }}
        typeOf={"draft"}
        text={"<< " + t("Back")}
      />
    </div>
  )
}

export const OutputBtn = ({
  isLoading = false,
  onClick,
  disabled,
  ...props
}) => {
  return (
    <OutputButton onClick={onClick} disabled={disabled || isLoading} {...props}>
      {isLoading && <Spin size={"small"} />}
      <img
        src={`${
          disabled
            ? "/assets/icons/grey-paper-with-cross-icon.svg"
            : "/assets/icons/paper-with-cross-icon.svg"
        }`}
        alt={"paper with cross icon "}
      />

      <span>{t("Output data")}</span>
    </OutputButton>
  )
}

export const InfoBox: React.FC<
  {
    children?: JSX.Element
    linkText: string
    href: string
  } & React.ComponentPropsWithRef<"div">
> = ({ children, linkText, href, ...props }) => {
  return (
    <InfoBoxWrapper
      className={`border-red common-box-container ${props.className}`}
      {...props}
    >
      <div className={"info-box-content"}>
        {children}
        <Link className={"link-text"} href={href}>
          <a className={"underline"}>{t(linkText) + ">"}</a>
        </Link>
      </div>
    </InfoBoxWrapper>
  )
}

export const NoticeBox = (props) => {
  const { siblings } = props
  const [isNoticeOpen, setIsNoticeOpen] = useState(false)

  const handleNoticeToggle = () => {
    setIsNoticeOpen(!isNoticeOpen)
  }
  return (
    <NoticeWrapper className={"common-box-container"}>
      <div className={`notice-content`}>
        {/* notice main title/btn starts here */}
        <button className={"notice-toggle-btn"} onClick={handleNoticeToggle}>
          <p className={"notice-content_title"}>
            <span className={"alert-icon"}>
              <img
                src={"/assets/icons/NotificationAlert.svg"}
                alt={"alter icon"}
              />
            </span>
            <span className={`down-facing-arrow ${isNoticeOpen && "active"}`}>
              <RedDownArrow />
            </span>
            <span className={"actual-notice-text"}>
              {t(
                "In the case of upper limit management with siblings, we cannot send claim information to the Federation of National Health Insurance Associations. Please print the upper limit management result slip and request it directly to the municipality."
              )}
            </span>
          </p>
        </button>
        {/* notice main title/btn ends here */}

        {/* notice more info starts here */}
        {
          <div className={`notice-content_more-info ${isNoticeOpen && "show"}`}>
            <div className={"notice-grid-contents"}>
              <p className={"notice-content_sub-title"}>
                {t(
                  "The following children cannot output data because they are children who manage the maximum amount of siblings."
                )}
              </p>
              {/* notice more info ends here */}

              {/* notice notice list starts here */}
              <ul>
                <li>
                  <div className={"notice-point-1 underline"}>
                    {siblings?.map((sib) => sib?.join("、"))}
                  </div>
                </li>
              </ul>
              {/* notice notice list ends here */}
            </div>
          </div>
        }
      </div>
    </NoticeWrapper>
  )
}

export const PrimaryActionButtons: React.FC<PrimaryActionButtonsProps> = ({
  hasExtraBtn,
  disableOutputBtn,
  handlePrint,
  handleCSVDownload,
  isCSVDownloading = false,
  outputCount = 0,
  handleTemporarySave,
  disablePrintBtn,
  isSaving = false,
  cautionMsg = "",
}) => {
  return (
    <>
      {cautionMsg && <CautionInfo>{cautionMsg}</CautionInfo>}
      <div className={"primary-action-btn-group flexbox"}>
        <OwnerButton
          text={t("Save")}
          typeOf={"draft"}
          onClick={handleTemporarySave}
          isLoading={isSaving}
        />
        <OwnerButton
          className={"print-btn"}
          text={t("Print")}
          typeOf={"print"}
          disabled={disablePrintBtn}
          onClick={() => {
            handlePrint()
          }}
        />
        <OutputBtn
          isLoading={isCSVDownloading}
          onClick={() => {
            handleCSVDownload(3)
          }}
          disabled={disableOutputBtn}
        />
        {hasExtraBtn && (
          <button className={"extra-stat-btn"}>
            {t("Total number of outputs")}
            {" : "}
            <span className={"bold-text"}>{outputCount}</span>
            {"件"}
          </button>
        )}
      </div>
    </>
  )
}

export * from "./FilterActionBlock"
export * from "./FacilityChildListBlock"
export * from "./ChangeDisplayBox"
export * from "./PrintFilterBlock"
