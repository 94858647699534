import { FACILITY_LOCATIONS } from "@project/shared"
import { notification } from "antd"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import cuid from "cuid"
import moment from "moment"
import { useRouter } from "next/router"
import React, { createContext, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import { fetchInstructors, fetchShuttleCars } from "../services"
import {
  getSingleTransportData,
  saveTransportBulkEdit,
} from "../services/transport"
import { AuthContext, scrollToElement } from "../utils"
import { toastMsg } from "../utils/toastMsg"
import { useUpdateSearchParams } from "../utils/useUpdateSearchParams"

type DisplayChangeProps = {
  facility: any[]
  service_type: any[]
  list_display: any[]
  date: any
}
type OPts = { label: string; value: number }
type TransportBulkEditContextProps = {
  facilities: any
  errorLists: any
  isCheckAll: boolean
  setIsCheckAll: (check: boolean) => void
  displayChange: DisplayChangeProps
  updatedDisplay: DisplayChangeProps
  setDisplayChange: (value: any) => void
  handleUpdateDisplay: any
  onFacilityChecked: (e: CheckboxChangeEvent) => void
  pickUp?: any
  setPickUp: (value: any) => void
  setUrlParams: (value: any) => void
  dropOff: any
  setDropOff: (value: any) => void
  isLoading?: boolean
  data?: any
  cars?: OPts[]
  instructors: OPts[]
  handleRowSelection: any
  pickRowSelection: any[]
  dropRowSelection: any[]
  handleReflection: () => void
  handleTableDataChange: (
    id: number,
    type: string,
    val: any,
    key: string,
    extra?: string
  ) => void
  handleReset: () => void
  handleResetOfIndividualRow: (id: number, type: string) => void
  handleExtraInfoChange: (
    id: number,
    val: any,
    key: string,
    type: string,
    extra?: string,
    minDefaultValue?: string
  ) => void
  handleDataSubmission: () => void
  isUpdating: boolean
  handleRequestDataMapping: any
  handleRequestDriverInfoMapping: any
  pickUpDriverRowSelection: React.Key[]
  dropOffDriverRowSelection: React.Key[]
  handlePickOrDropDriverTableRowSel: (
    newSelectedRowKeys: React.Key[],
    type?: "pick_up" | "drop_off"
  ) => void
  handleNewDriverAddition?: (type: string, data: any) => void
  handleDriverRemove?: (type: string, id: any) => void
}
const TransportBulkEditContext = createContext<
  Partial<TransportBulkEditContextProps>
>({})
const initialState = {
  desired_time: {
    hr: "",
    min: "",
  },
  pickup_time: {
    hr: "",
    min: "",
  },
  arrival_time: {
    hr: "",
    min: "",
  },
  transportation_start_time: {
    hr: "",
    min: "",
  },
  transportation_end_time: {
    hr: "",
    min: "",
  },
  after_day: null,
  child_dev: null,
  escort_staff_id: null,
  driver_staff_id: null,
  car_id: null,
  route: 0,
  place: null,
  other_place: "",
}

const TransportBulkEditContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { t } = useTranslation()
  const router = useRouter()

  const { facilities } = useContext(AuthContext)
  const [updateParams] = useUpdateSearchParams()
  const [isCheckAll, setIsCheckAll] = useState(false)

  const defaultFacility = router?.query?.facilityIds as string
  const defaultServiceType = router?.query?.service_ids as string
  const defaultListDisplay = router?.query?.list_display as string
  const defaultDate = router?.query?.date as string

  const initialFilterValues = {
    facility:
      defaultFacility?.split(",") ||
      facilities
        ?.map((val) => val?.id.toString())
        ?.filter((facId) =>
          facilities?.find((f) => f?.id == facId) ? true : false
        ) ||
      [],
    service_type: defaultServiceType?.split(",") || [],
    list_display: defaultListDisplay?.split(",") || ["1", "2"],
    date: defaultDate,
  }

  const [displayChange, setDisplayChange] = useState<DisplayChangeProps>({
    ...initialFilterValues,
  })
  const [updatedDisplay, setUpdateDisplay] = useState<any>({
    ...initialFilterValues,
  })
  const [pickUp, setPickUp] = useState({
    ...initialState,
    s_furigana: "",
    status: null,
  })
  const [dropOff, setDropOff] = useState({
    ...initialState,
    d_furigana: "",
    status: null,
  })
  const [pickRowSelection, setPickRowSelection] = useState<React.Key[]>([])
  const [dropRowSelection, setDropRowSelection] = useState<React.Key[]>([])
  const [pickUpDriverRowSelection, setPickUpDriverRowSelection] = useState<
    React.Key[]
  >([])
  const [dropOffDriverRowSelection, setDropOffDriverRowSelection] = useState<
    React.Key[]
  >([])
  const [urlParams, setUrlParams] = useState(null)

  const [tableData, setTableData] = useState<any>({
    pick_up: [],
    pickUpOtherInfo: [],
    drop_off: [],
    dropOffOtherInfo: [],
  })
  const [errorLists, setErrorLists] = useState(null)

  useEffect(() => {
    if (!router?.query?.date) {
      const date = moment().format("YYYY-MM-DD")
      setDisplayChange({
        ...displayChange,
        date,
      })
      updateParams({ date }, "/user-transport-management/bulk-edit")
    }
  }, [])
  const handleUpdateDisplay = () => {
    const data = {
      ...displayChange,
      facility: displayChange?.facility?.join(","),
      service_type: displayChange?.service_type?.join(","),
      list_display: displayChange?.list_display?.join(",") || `1,2`,
      date: moment(displayChange?.date).format("YYYY-MM-DD"),
    }
    setUpdateDisplay(data)
    updateParams(data, "/user-transport-management/bulk-edit")
    scrollToElement("")
  }
  const handleTime = (value: any) => {
    return {
      hr: value ? `${value?.split(":")[0]}`.slice(-2) : "",
      min: value ? `${value?.split(":")[1]}`.slice(-2) : "",
    }
  }

  //fetch data
  const { isLoading } = useQuery({
    queryKey: ["single_transport", updatedDisplay],
    queryFn: () =>
      getSingleTransportData({
        year: `${moment(updatedDisplay?.date || defaultDate).format("YYYY")}`,
        month: `${moment(updatedDisplay?.date || defaultDate).format("MM")}`,
        day: `${moment(updatedDisplay?.date || defaultDate).format("DD")}`,
        facility_ids: displayChange?.facility?.join(","),
        service_ids: displayChange?.service_type?.join(","),
        list_display: displayChange?.list_display?.join(","),
      }),
    select: (res) => ({
      ...res,
      data: {
        ...res?.data,
        pickup: res?.data?.pickup?.map((val, i) => ({
          ...val,
          id: val?.id || new Date().getTime() + i,
          key: val?.id || new Date().getTime() + i,
          desired_time: handleTime(val?.desired_pickup_time),
          pickup_time: handleTime(val?.time),
          arrival_time: handleTime(val?.arrival_time),

          /**
           * if the service type is 2 then it means the place is Place (Child dev)
           * else it means the place is Place(After day)
           * [Note: We need to set them like this is because while submitting the data
           * we are sending only place_id and other_place, so we need to set them
           * according to the service type in front_end]
           */

          place_id:
            val?.service_type === "2"
              ? val?.place_id
                ? Number(val?.place_id)
                : val?.desired_pick_up_place
                ? FACILITY_LOCATIONS?.find(
                    (loc) => loc?.value == val?.desired_pick_up_place
                  )?.locationId || 3
                : null
              : null,
          other_place:
            val?.service_type === "2" && val?.place_id == 3
              ? val?.desired_pick_up_place
              : null,

          after_day:
            val?.service_type === "1"
              ? val?.place_id
                ? Number(val?.place_id)
                : val?.desired_pick_up_place
                ? FACILITY_LOCATIONS?.find(
                    (loc) => loc?.value == val?.desired_pick_up_place
                  )?.locationId || 3
                : null
              : null,
          after_place:
            val?.service_type === "1" && val?.place_id == 3
              ? val?.desired_pick_up_place
              : null,

          is_present: val?.is_present ? true : false,
          use_transport: val?.use_transport ? true : false,
          child_name_furigana: 0,
        })),
        pickup_driver: res?.data?.pickup_driver
          ?.filter((driver) => (driver?.driver_staff_id ? true : false))
          ?.map((val, i) => ({
            ...val,
            id: new Date().getTime() + i,
            key: new Date().getTime() + i,
            start_time: handleTime(val?.start_time),
            end_time: handleTime(val?.end_time),
            driver_staff_furigana_name: 0,
          })),
        dropoff: res?.data?.dropoff?.map((val, i) => ({
          ...val,
          id: val?.id || new Date().getTime() + i,
          key: val?.id || new Date().getTime() + i,
          desired_time: handleTime(val?.desired_drop_time),
          pickup_time: handleTime(val?.time),
          arrival_time: handleTime(val?.arrival_time),

          /* Same logic here */
          place_id:
            val?.service_type === "2"
              ? val?.place_id
                ? Number(val?.place_id)
                : val?.desired_drop_off_place
                ? FACILITY_LOCATIONS?.find(
                    (loc) => loc?.value == val?.desired_drop_off_place
                  )?.locationId || 3
                : null
              : null,
          other_place:
            val?.service_type === "2" && val?.place_id == 3
              ? val?.desired_drop_off_place
              : null,

          after_day:
            val?.service_type === "1"
              ? val?.place_id
                ? Number(val?.place_id)
                : val?.desired_drop_off_place
                ? FACILITY_LOCATIONS?.find(
                    (loc) => loc?.value == val?.desired_drop_off_place
                  )?.locationId || 3
                : null
              : null,
          after_place:
            val?.service_type === "1" && val?.place_id == 3
              ? val?.desired_drop_off_place
              : null,

          is_present: val?.is_present ? true : false,
          use_transport: val?.use_transport ? true : false,
          child_name_furigana: 0,
        })),
        dropoff_driver: res?.data?.dropoff_driver
          ?.filter((driver) => (driver?.driver_staff_id ? true : false))
          ?.map((val, i) => ({
            ...val,
            id: new Date().getTime() + i,
            key: new Date().getTime() + i,
            start_time: handleTime(val?.start_time),
            end_time: handleTime(val?.end_time),
            driver_staff_furigana_name: 0,
          })),
      },
    }),
    onSuccess: (res) => {
      setTableData({
        ...tableData,
        pick_up: res?.data?.pickup,
        pickUpOtherInfo: res?.data?.pickup_driver || {},
        drop_off: res?.data?.dropoff,
        dropOffOtherInfo: res?.data?.dropoff_driver || {},
      })
      setPickRowSelection(res?.data?.pickup?.map((val) => val?.key))
      setDropRowSelection(res?.data?.dropoff?.map((val) => val?.key))
      setDropOffDriverRowSelection(
        res?.data?.dropoff_driver?.map((val) => val?.key)
      )
      setPickUpDriverRowSelection(
        res?.data?.pickup_driver?.map((val) => val?.key)
      )
    },
    refetchOnWindowFocus: false,
    retry: 0,
    keepPreviousData: true,
  })

  //fetch cars
  const { data: cars } = useQuery({
    queryKey: ["cars--"],
    queryFn: () => fetchShuttleCars(1, "infinity"),
    keepPreviousData: true,
    select: (res) =>
      res?.data?.map((val) => ({
        label: val?.car_name,
        value: val?.id,
      })),
    retry: 0,
    refetchOnWindowFocus: false,
  })

  //fetch instructor
  const { data: instructors } = useQuery({
    queryKey: ["instructors"],
    queryFn: () => fetchInstructors({ page: 1, pageSize: "Infinity" }),
    keepPreviousData: true,
    select: (res) =>
      res?.data?.map((val) => ({
        label: val?.staff_name,
        value: val?.id,
        furigna: val?.staff_name_furiganaame,
      })),
    refetchOnWindowFocus: false,
    retry: 0,
  })
  //updated  mutation
  const { isLoading: isUpdating, mutate } = useMutation(saveTransportBulkEdit, {
    onSuccess: () => {
      toastMsg(
        "success",
        t("Transportation management") + t("Updated Successfully")
      )

      const joinedFacilityIds =
        typeof displayChange.facility === "string"
          ? (displayChange.facility as string).split(",").join("%2C")
          : displayChange.facility.join("%2C")
      const transformedListDisplay = displayChange.list_display.map((item) => {
        return item === "1" ? "迎え" : item === "2" ? "送り" : item
      })
      const result = transformedListDisplay.join("%2C")

      const joinedServiceType =
        typeof displayChange.service_type === "string"
          ? (displayChange.service_type as string).split(",").join("%2C")
          : displayChange.service_type.join("%2C")

      let url = `/user-transport-management/${urlParams?.date}?`
      if (displayChange.facility) {
        url += "&facilityIds=" + joinedFacilityIds
      }
      if (displayChange.list_display) {
        url += "&listDisplay=" + result
      }
      if (displayChange.service_type) {
        url += "&service_ids=" + joinedServiceType
      }

      router.push(url)
    },
    onError: (err: any) => {
      const msg =
        err?.status == 403
          ? "User doesn't have permission to edit this page"
          : "Something went wrong. Please contact administrator."
      toastMsg("error", t(msg))
    },
  })

  const onFacilityChecked = (e: any) => {
    const { value, checked } = e.target

    if (!checked) {
      setDisplayChange({
        ...displayChange,
        facility: displayChange?.facility?.filter(
          (item) => item !== `${value}`
        ),
      })
    } else {
      setDisplayChange({
        ...displayChange,
        facility: [...displayChange?.facility, `${value}`],
      })
    }
  }

  const handleRowSelection = (
    newSelectedRowKeys: React.Key[],
    type?: string
  ) => {
    if (type === "pick_up") {
      setPickRowSelection(newSelectedRowKeys)
    } else {
      setDropRowSelection(newSelectedRowKeys)
    }
  }

  // handle row selection for pick up and drop off table
  const handlePickOrDropDriverTableRowSel = (
    newSelectedRowKeys: React.Key[],
    type?: "pick_up" | "drop_off"
  ) => {
    if (type === "pick_up") {
      setPickUpDriverRowSelection(newSelectedRowKeys)
    } else {
      setDropOffDriverRowSelection(newSelectedRowKeys)
    }
  }

  const handleDataMapping = (data: any[], newState: any) => {
    const d = data?.map((val) =>
      pickRowSelection?.find((key) => val?.id === key)
        ? {
            ...val,
            desired_time: {
              hr: newState?.desired_time?.hr || val?.desired_time?.hr,
              min: newState?.desired_time?.min || val?.desired_time?.min,
            },
            pickup_time: {
              hr: newState?.pickup_time?.hr || val?.pickup_time?.hr,
              min: newState?.pickup_time?.min || val?.pickup_time?.min,
            },
            arrival_time: {
              hr: newState?.arrival_time?.hr || val?.arrival_time?.hr,
              min: newState?.arrival_time?.min || val?.arrival_time?.min,
            },
            after_day: newState?.after_day || val?.after_day,
            place_id: newState?.place_id || val?.place_id,
            escort_staff_id:
              newState?.escort_staff_id || val?.escort_staff_id || null,
            driver_staff_id:
              newState?.driver_staff_id || val?.driver_staff_id || null,
            car_id: newState?.car_id || val?.car_id || null,
            route: newState?.route || val?.route || 0,
            other_place: newState?.other_place || val?.other_place || null,
            after_place: newState?.after_place || val?.after_place || null,
            use_transport: newState?.status
              ? newState?.status === "yes"
              : val?.use_transport,
          }
        : val
    )

    return d
  }

  const handleDropOffDataMapping = (data: any[], newState: any) => {
    const d = data?.map((val) =>
      dropRowSelection?.find((key) => val?.id === key)
        ? {
            ...val,
            desired_time: {
              hr: newState?.desired_time?.hr || val?.desired_time?.hr,
              min: newState?.desired_time?.min || val?.desired_time?.min,
            },
            pickup_time: {
              hr: newState?.pickup_time?.hr || val?.pickup_time?.hr,
              min: newState?.pickup_time?.min || val?.pickup_time?.min,
            },
            arrival_time: {
              hr: newState?.arrival_time?.hr || val?.arrival_time?.hr,
              min: newState?.arrival_time?.min || val?.arrival_time?.min,
            },
            after_day: newState?.after_day || val?.after_day,
            place_id: newState?.place_id || val?.place_id,
            escort_staff_id:
              newState?.escort_staff_id || val?.escort_staff_id || null,
            driver_staff_id:
              newState?.driver_staff_id || val?.driver_staff_id || null,
            car_id: newState?.car_id || val?.car_id || null,
            route: newState?.route || val?.route || 0,
            other_place: newState?.other_place || val?.other_place || null,
            after_place: newState?.after_place || val?.after_place || null,
            use_transport: newState?.status
              ? newState?.status === "yes"
              : val?.use_transport,
          }
        : val
    )

    return d
  }

  const handleDataMappingForInfo = (
    data: any[],
    newState: any,
    type: "pick_up" | "drop_off"
  ) => {
    let updatedArray = data.map((item) => {
      const errors = {
        facility_id_error:
          newState?.facility_id || item?.facility_id ? null : "required",
        driver_staff_id_error:
          newState?.driver_staff_id || item?.driver_staff_id
            ? null
            : "required",
        car_id_error: newState?.car_id || item?.car_id ? null : "required",
        start_time_hr_error:
          newState?.transportation_start_time?.hr || item?.start_time?.hr
            ? null
            : "required",
        start_time_min_error:
          newState?.transportation_start_time?.min || item?.start_time?.min
            ? null
            : "required",
        end_time_hr_error:
          newState?.transportation_end_time?.hr || item?.end_time?.hr
            ? null
            : "required",
        end_time_min_error:
          newState?.transportation_end_time?.min || item?.end_time?.min
            ? null
            : "required",
        route_error: newState?.route || item?.route ? null : "required",
      }
      const d = {
        ...item,
        ...errors,
        escort_staff_id:
          newState?.escort_staff_id || item?.escort_staff_id || null,
        driver_staff_id:
          newState?.driver_staff_id || item?.driver_staff_id || null,
        car_id: newState?.car_id || item?.car_id || null,
        start_time: {
          hr: newState?.transportation_start_time?.hr || item?.start_time?.hr,
          min:
            newState?.transportation_start_time?.min || item?.start_time?.min,
        },
        end_time: {
          hr: newState?.transportation_end_time?.hr || item?.end_time?.hr,
          min: newState?.transportation_end_time?.min || item?.end_time?.min,
        },
      }

      if (
        type === "pick_up" &&
        pickUpDriverRowSelection?.includes(item?.key) &&
        newState?.status === "yes"
      ) {
        if (item.key === d.key) {
          return { ...d }
        }
      }
      if (
        type === "pick_up" &&
        pickUpDriverRowSelection?.includes(item?.key) &&
        newState?.status === "no"
      ) {
        return undefined
      }
      if (
        type === "drop_off" &&
        dropOffDriverRowSelection?.includes(item?.key) &&
        newState?.status === "yes"
      ) {
        if (item.key === d.key) {
          return { ...d }
        }
      }
      if (
        type === "drop_off" &&
        dropOffDriverRowSelection?.includes(item?.key) &&
        newState?.status === "no"
      ) {
        return undefined
      }
      return item
    })

    if (updatedArray?.length == 0) {
      // if no data
      if (newState?.status == "yes" && newState?.driver_staff_id) {
        // if adding driver
        const newKey = cuid()
        updatedArray = [
          {
            facility_id: "",
            facility_name_furigana: "",
            car_name: null,
            route: 0,
            driver_staff_furigana_name: 0,
            escort_staff_furigana_name: null,
            id: newKey,
            key: newKey,
            isNew: true,
            escort_staff_id: newState?.escort_staff_id || null,
            driver_staff_id: newState?.driver_staff_id || null,
            car_id: newState?.car_id || null,
            start_time: {
              hr: newState?.transportation_start_time?.hr || "",
              min: newState?.transportation_start_time?.min || "",
            },
            end_time: {
              hr: newState?.transportation_end_time?.hr || "",
              min: newState?.transportation_end_time?.min || "",
            },
          },
        ]

        // checkbox
        if (type == "pick_up") {
          setPickUpDriverRowSelection((prev) => [...prev, newKey])
        } else if (type == "drop_off") {
          setDropOffDriverRowSelection((prev) => [...prev, newKey])
        }
      }
    }

    return updatedArray?.filter((v) => v?.key)
  }

  const handleReflection = () => {
    // Condition for selection for now comment
    setTableData({
      pick_up: handleDataMapping(tableData?.pick_up, pickUp),
      pickUpOtherInfo: handleDataMappingForInfo(
        tableData?.pickUpOtherInfo,
        pickUp,
        "pick_up"
      ),
      drop_off: handleDropOffDataMapping(tableData?.drop_off, dropOff),
      dropOffOtherInfo: handleDataMappingForInfo(
        tableData?.dropOffOtherInfo,
        dropOff,
        "drop_off"
      ),
    })
  }

  const setInitialData = (
    data: any,
    action: "single" | "multi",
    id?: number
  ) => {
    const defaultValue = {
      desired_pickup_time: null,
      arrival_time: {
        hr: "",
        min: "",
      },
      place_id: null,
      other_place: null,
      use_transport: false,
      desired_time: {
        hr: "",
        min: "",
      },
      pickup_time: {
        hr: "",
        min: "",
      },
    }
    const filterData = data?.map((val) => {
      if (action === "multi") {
        return {
          ...val,
          ...defaultValue,
        }
      }
      if (val?.id === id) {
        return {
          ...val,
          ...defaultValue,
          desired_time: val?.desired_time || {
            hr: "",
            min: "",
          },
          isDataAdding: true,
        }
      }
      return val
    })
    return filterData
  }

  const handleReset = () => {
    const d = {}
    const handleDataChange = (data, driverInfo, type) => {
      const prevData = data?.filter((v) => !pickRowSelection?.includes(v?.id))
      const dataToModify = data?.filter((v) =>
        pickRowSelection?.includes(v?.id)
      )
      const modifiedData = setInitialData(dataToModify, "multi")
      const finalData = [...prevData, ...modifiedData]
      const otherInfo = driverInfo?.map((value) => {
        if (
          type === "pick_up" &&
          pickUpDriverRowSelection?.includes(value?.key)
        ) {
          return {
            ...value,
            driver_staff_id: null,
            car_id: null,
            car_name: null,
            route: 0,
            escort_staff_id: null,
            escort_staff_name: null,
            escort_staff_furigana_name: null,
            start_time: {
              hr: "",
              min: "",
            },
            end_time: {
              hr: "",
              min: "",
            },
          }
        } else if (
          type === "drop_off" &&
          dropOffDriverRowSelection?.includes(value?.key)
        ) {
          return {
            ...value,
            driver_staff_id: null,
            car_id: null,
            car_name: null,
            route: 0,
            escort_staff_id: null,
            escort_staff_name: null,
            escort_staff_furigana_name: null,
            start_time: {
              hr: "",
              min: "",
            },
            end_time: {
              hr: "",
              min: "",
            },
          }
        }

        return value
      })
      return { finalData, otherInfo }
    }
    if (pickRowSelection?.length >= 1) {
      const { finalData, otherInfo } = handleDataChange(
        tableData?.pick_up,
        tableData?.pickUpOtherInfo,
        "pick_up"
      )
      const removeSelectedDriver = otherInfo
        ?.map((v) => {
          if (!pickUpDriverRowSelection?.includes(v?.key)) {
            return {
              ...v,
            }
          }
        })
        ?.filter((v) => v?.key)

      d[`pick_up`] = finalData
      d[`pickUpOtherInfo`] = removeSelectedDriver
    }
    if (dropRowSelection?.length >= 1) {
      const { finalData, otherInfo } = handleDataChange(
        tableData?.drop_off,
        tableData?.dropOffOtherInfo,
        "drop_off"
      )
      const removeSelectedDriver = otherInfo
        ?.map((v) => {
          if (!dropOffDriverRowSelection?.includes(v?.key)) {
            return {
              ...v,
            }
          }
        })
        ?.filter((v) => v?.key)
      d[`drop_off`] = finalData
      d[`dropOffOtherInfo`] = removeSelectedDriver
    }
    setTableData({
      ...tableData,
      ...d,
    })
  }

  const handleTableDataChange = (
    id: number,
    type: string,
    val: any,
    key: string,
    extra?: string
  ) => {
    const data = type === "PICKUP" ? tableData?.pick_up : tableData?.drop_off
    const changeOf = type === "PICKUP" ? "pick_up" : "drop_off"
    const newData = data?.map((value) => {
      if (value?.id === id) {
        if (
          key === "desired_time" ||
          key === "pickup_time" ||
          key === "arrival_time"
        ) {
          const obj = {
            ...value,
            [key]: {
              ...value[key],
              [extra]: val,
            },
          }
          if (extra == "hr" && !obj[key]?.min) {
            obj[key]["min"] = "00"
          }
          return obj
        }
        if (key === "route") {
          return {
            ...value,
            [key]: val,
            [`${key}_error`]: val ? null : "required",
          }
        }
        return {
          ...value,
          [key]: val,
        }
      }
      return value
    })
    setTableData({
      ...tableData,
      [changeOf]: newData,
    })
  }

  const handleResetOfIndividualRow = (id: number, type: string) => {
    const key = type === "PICKUP" ? "pick_up" : "drop_off"
    const state = type === "PICKUP" ? tableData?.pick_up : tableData?.drop_off
    const filterData = setInitialData(state, "single", id)
    setTableData({
      ...tableData,
      [key]: filterData,
    })
  }

  const handleExtraInfoChange = (
    id: number,
    val: any,
    key: string,
    type: string,
    extra?: string,
    minDefaultValue?: string
  ) => {
    const changeOf = type === "PICKUP" ? "pickUpOtherInfo" : "dropOffOtherInfo"

    const data =
      type === "PICKUP"
        ? tableData?.pickUpOtherInfo
        : tableData?.dropOffOtherInfo
    const newData = data?.map((value) => {
      if (value?.id === id) {
        if (key === "start_time" || key === "end_time") {
          /**
           * If min value is not set then set it to 00
           * when hour value is set
           **/
          const minValObj = {}

          /**
           * since we will be setting min value to 00
           * when hour value is set it means we can be sure that
           * min value is set to 00 when we change our hr value
           * that means, we can set min_error to null
           * */
          const min_error = {}
          if (extra === "hr" && !value[key]?.min) {
            minValObj["min"] = minDefaultValue || "00"
            min_error[`${key}_min_error`] = null // This piece of code will set min_error to null
          }

          const values = {
            ...value,
            [key]: {
              ...value[key],
              [extra]: val,
              ...minValObj, // This piece of code will set min value to 00
            },
            [`${key}_${extra}_error`]: val ? null : "required",
            ...min_error, // This piece of code will set min_error to null
          }
          if (key === "start_time") {
            return {
              ...values,
              end_time: {
                hr: null,
                min: null,
              },
            }
          }
          return {
            ...values,
          }
        } else if (
          key === "driver_staff_furigana_name" ||
          key === "escort_staff_furigana_name"
        ) {
          return {
            ...value,
            [key]: val,
            [extra]: null,
          }
        } else if (key === "car_id") {
          return {
            ...value,
            [key]: val,
            route: !value.route ? 0 : value.route,
            [`${key}_error`]: val ? null : "required",
          }
        }
        return {
          ...value,
          [key]: val,
          [`${key}_error`]: val ? null : "required",
        }
      }
      return value
    })
    setTableData({
      ...tableData,
      [changeOf]: newData,
    })
  }

  // data mapping for api request
  const handleRequestDataMapping = (data) => {
    return data?.map((val) => ({
      child_id: val?.child_id,
      facility_id: val?.facility_id,
      desired_time: val?.desired_time?.hr
        ? `${val?.desired_time?.hr}:${val?.desired_time?.min || "00"}`
        : "",
      time: val?.pickup_time?.hr
        ? `${val?.pickup_time?.hr}:${val?.pickup_time?.min || "00"}`
        : "",
      arrival_time: val?.arrival_time?.hr
        ? `${val?.arrival_time?.hr}:${val?.arrival_time?.min || "00"}`
        : "",
      // if service_type is 1 then it is a after day or else its a other day
      place: val.service_type === "1" ? val?.after_day : val?.place_id,
      other_place:
        val.service_type === "1" ? val?.after_place : val?.other_place,

      remarks: val?.remarks || "",
      route: +val?.route || 0,
      use_transport: val?.use_transport || false,
    }))
  }

  const handleRequestDriverInfoMapping = (data) => {
    return data?.map((val) => ({
      driver_staff_id: val?.driver_staff_id,
      escort_staff_id: val?.escort_staff_id,
      facility_id: val?.facility_id,
      car_id: val?.car_id,
      route: val?.route,
      start_time: val?.start_time?.hr
        ? `${val?.start_time?.hr}:${val?.start_time?.min || "00"}`
        : "",
      end_time: val?.start_time?.hr
        ? `${val?.end_time?.hr}:${val?.end_time?.min || "00"}`
        : "",
    }))
  }

  //add new driver
  const handleNewDriverAddition = (type: string, newData: any) => {
    const key = type === "PICKUP" ? "pickUpOtherInfo" : "dropOffOtherInfo"
    if (type === "PICKUP") {
      setPickUpDriverRowSelection([...pickUpDriverRowSelection, newData?.key])
    }
    if (type === "DROPOFF") {
      setDropOffDriverRowSelection([...dropOffDriverRowSelection, newData?.key])
    }
    setTableData({
      ...tableData,
      [key]: [...tableData[key], newData],
    })
  }
  const handleDriverRemove = (type: string, id: any) => {
    const key = type === "PICKUP" ? "pickUpOtherInfo" : "dropOffOtherInfo"
    const filterData = tableData[key]?.filter((value) => value?.id !== id)
    setTableData({
      ...tableData,
      [key]: filterData,
    })
  }

  const handleDataSubmission = () => {
    let hasError = false

    const filterTableBeforeSubmission = {
      ...tableData,
      pick_up: tableData?.pick_up
        ?.filter((d) => d?.is_present || d?.is_unregistered) // pass only those data which are not absent
        ?.map((data) => {
          const erroredRow = {}
          if (
            !data?.is_unregistered &&
            data?.is_present &&
            data?.use_transport
          ) {
            if (!data?.route) {
              erroredRow["route_error"] = !data?.route ? "required" : null
              setErrorLists(erroredRow)
            }
            for (const key in erroredRow) {
              if (erroredRow[key] !== null) {
                hasError = true
                break
              }
            }
            return {
              ...data,
              ...erroredRow,
            }
          }
          return {
            ...data,
            ...erroredRow,
          }
        }),
      drop_off: tableData?.drop_off
        ?.filter((d) => d?.is_present || d?.is_unregistered) // pass only those data which are not absent
        ?.map((data) => {
          const erroredRow = {}
          if (
            !data?.is_unregistered &&
            data?.is_present &&
            data?.use_transport
          ) {
            if (!data?.route) {
              erroredRow["route_error"] = !data?.route ? "required" : null
              setErrorLists(erroredRow)
            }
            for (const key in erroredRow) {
              if (erroredRow[key] !== null) {
                hasError = true
                break
              }
            }
            return {
              ...data,
              ...erroredRow,
            }
          }
          return {
            ...data,
            ...erroredRow,
          }
        }),
      dropOffOtherInfo: tableData?.dropOffOtherInfo?.map((dropOffInfo) => {
        /**
         * We wanna prevent user from submitting the data if at least one row is filled
         * but other fields are empty, so we are checking if at least one row has been filled
         */
        const hasAtleastOneRowData =
          dropOffInfo?.driver_staff_id ||
          dropOffInfo?.car_id ||
          dropOffInfo?.route ||
          dropOffInfo?.start_time?.hr ||
          dropOffInfo?.end_time?.hr

        const erroredRow = {}

        /**
         * Here if there is at least one row data then we are checking
         * if other required fields are filled or not
         * if not then we are setting the error message i.e. 'required'
         * else we are setting the error message to null
         * ! [NOTE]: Route number can be null if vehicle i.e. car_id is not assigned/selected
         */

        if (dropOffDriverRowSelection?.includes(dropOffInfo?.key)) {
          erroredRow["driver_staff_id_error"] = !dropOffInfo?.driver_staff_id
            ? "required"
            : null
        }
        if (hasAtleastOneRowData) {
          erroredRow["facility_id_error"] = !dropOffInfo?.facility_id
            ? "required"
            : null

          erroredRow["driver_staff_id_error"] = !dropOffInfo?.driver_staff_id
            ? "required"
            : null

          erroredRow["car_id_error"] = !dropOffInfo?.car_id ? "required" : null
          erroredRow["route_error"] = dropOffInfo?.car_id
            ? !dropOffInfo?.route
              ? "required"
              : null
            : null

          erroredRow["start_time_hr_error"] =
            updatedDisplay?.list_display?.includes("2")
              ? !dropOffInfo?.start_time?.hr
                ? "required"
                : null
              : null

          erroredRow["start_time_min_error"] =
            updatedDisplay?.list_display?.includes("2")
              ? !dropOffInfo?.start_time?.min
                ? "required"
                : null
              : null

          erroredRow["end_time_hr_error"] =
            updatedDisplay?.list_display?.includes("2")
              ? !dropOffInfo?.end_time?.hr
                ? "required"
                : null
              : null

          erroredRow["end_time_min_error"] =
            updatedDisplay?.list_display?.includes("2")
              ? !dropOffInfo?.end_time?.min
                ? "required"
                : null
              : null
          setErrorLists(erroredRow)
        }

        /**
         * Here we are checking if there is any error in the row
         * if there is any error then we are setting the hasError to true
         * 'hasError' flag will be used to prevent user from submitting the data
         */
        for (const key in erroredRow) {
          if (erroredRow[key] !== null) {
            hasError = true
            break
          }
        }

        return {
          ...dropOffInfo,
          facility_id: +dropOffInfo?.facility_id || null,
          ...erroredRow,
        }
      }),
      pickUpOtherInfo: tableData?.pickUpOtherInfo?.map((pickUpInfo) => {
        /**
         * We wanna prevent user from submitting the data if at least one row is filled
         * but other fields are empty, so we are checking if at least one row is filled
         */
        const hasAtleastOneRowData =
          pickUpInfo?.driver_staff_id ||
          pickUpInfo?.car_id ||
          pickUpInfo?.route ||
          pickUpInfo?.start_time?.hr ||
          pickUpInfo?.end_time?.hr

        const erroredRow = {}

        /**
         * Here if there is at least one row data then we are checking
         * if the required fields are filled or not
         * if not then we are setting the error message i.e. 'required'
         * else we are setting the error message to null
         * ! [NOTE]: Route number can be null if vehicle i.e. car_id is not assigned/selected
         */
        if (pickUpDriverRowSelection?.includes(pickUpInfo?.key)) {
          erroredRow["driver_staff_id_error"] = !pickUpInfo?.driver_staff_id
            ? "required"
            : null
        }
        if (hasAtleastOneRowData) {
          erroredRow["facility_id_error"] = !pickUpInfo?.facility_id
            ? "required"
            : null
          erroredRow["driver_staff_id_error"] = !pickUpInfo?.driver_staff_id
            ? "required"
            : null

          erroredRow["car_id_error"] = !pickUpInfo?.car_id ? "required" : null

          erroredRow["route_error"] = pickUpInfo?.car_id
            ? !pickUpInfo?.route
              ? "required"
              : null
            : null

          erroredRow["start_time_hr_error"] =
            updatedDisplay?.list_display?.includes("1")
              ? !pickUpInfo?.start_time?.hr
                ? "required"
                : null
              : null

          erroredRow["start_time_min_error"] =
            updatedDisplay?.list_display?.includes("1")
              ? !pickUpInfo?.start_time?.min
                ? "required"
                : null
              : null

          erroredRow["end_time_hr_error"] =
            updatedDisplay?.list_display?.includes("1")
              ? !pickUpInfo?.end_time?.hr
                ? "required"
                : null
              : null

          erroredRow["end_time_min_error"] =
            updatedDisplay?.list_display?.includes("1")
              ? !pickUpInfo?.end_time?.min
                ? "required"
                : null
              : null
          setErrorLists(erroredRow)
        }

        /**
         * Here we are checking if there is any error in the row
         * if there is any error then we are setting the hasError to true
         */
        for (const key in erroredRow) {
          if (erroredRow[key] !== null) {
            hasError = true
            break
          }
        }
        return {
          ...pickUpInfo,
          ...erroredRow,
        }
      }),
    }

    setTableData(filterTableBeforeSubmission)

    /**
     * If there is any error then we are returning from here
     * else we are submitting the data
     */

    if (hasError) {
      notification.error({
        type: "error",
        message: t("Please fill all the required fields"),
      })
      return
    }

    const data = {
      year: `${moment(defaultDate).format("YYYY")}`,
      month: `${moment(defaultDate).format("MM")}`,
      day: `${moment(defaultDate).format("DD")}`,
      pickup_info: {
        driver_detail: handleRequestDriverInfoMapping(
          tableData?.pickUpOtherInfo
        ),
        data: handleRequestDataMapping(tableData?.pick_up),
      },
      dropoff_info: {
        driver_detail: handleRequestDriverInfoMapping(
          tableData?.dropOffOtherInfo
        ),
        data: handleRequestDataMapping(tableData?.drop_off),
      },
    }
    mutate(data)
  }

  return (
    <TransportBulkEditContext.Provider
      value={{
        cars,
        pickUp,
        dropOff,
        setPickUp,
        isLoading,
        facilities,
        isCheckAll,
        setDropOff,
        isUpdating,
        errorLists,
        instructors,
        handleReset,
        displayChange,
        setIsCheckAll,
        updatedDisplay,
        data: tableData,
        setDisplayChange,
        pickRowSelection,
        dropRowSelection,
        handleReflection,
        onFacilityChecked,
        handleRowSelection,
        handleUpdateDisplay,
        handleDataSubmission,
        handleTableDataChange,
        handleExtraInfoChange,
        handleRequestDataMapping,
        pickUpDriverRowSelection,
        dropOffDriverRowSelection,
        handleResetOfIndividualRow,
        setUrlParams,
        handleRequestDriverInfoMapping,
        handlePickOrDropDriverTableRowSel,
        handleNewDriverAddition,
        handleDriverRemove,
      }}
    >
      {children}
    </TransportBulkEditContext.Provider>
  )
}

const useTransportBulkEditContext = () => {
  const context = useContext(TransportBulkEditContext)
  if (context === undefined) {
    throw new Error(
      "useTransportBulkEditContext must be used within a TransportBulkEditContextProvider"
    )
  }
  return context
}

export { TransportBulkEditContextProvider, useTransportBulkEditContext }
