interface IExtendedSupportAdditionTime {
  service_start_hour1: string
  service_start_minute1: string
  service_end_hour1: string
  service_end_minute1: string
  service_start_hour2: string
  service_start_minute2: string
  service_end_hour2: string
  service_end_minute2: string
  service_start_hour3: string
  service_start_minute3: string
  service_end_hour3: string
  service_end_minute3: string
  attendance_start_hour: string
  attendance_start_minute: string
  attendance_end_hour: string
  attendance_end_minute: string
  service_type: number
  form_of_provision: number
}

interface ExtendedSupportAdditionTime {
  has_extended_support_time1: boolean
  extended_start_hour1: number
  extended_start_minute1: number
  extended_end_hour1: number
  extended_end_minute1: number
  has_extended_support_time2: boolean
  extended_start_hour2: number
  extended_start_minute2: number
  extended_end_hour2: number
  extended_end_minute2: number
}

interface IExtendedSupportAdditionTimeForNoServiceTimeOrNoOverlap {
  attendance_start_hour: string
  attendance_start_minute: string
  attendance_end_hour: string
  attendance_end_minute: string
  standard_hours: number
}

interface IExtendedHourOverlap {
  service_start_hour: string
  service_start_minute: string
  attendance_start_hour: string
  attendance_start_minute: string
  extended_hours: number
}

interface IHoursAndCategory {
  hours: number
  minutes: number
  category: number
  number_of_hours: number
}

interface ISuitableServiceProvisionTime {
  service_start_hour1: string
  service_start_minute1: string
  service_end_hour1: string
  service_end_minute1: string
  service_start_hour2: string
  service_start_minute2: string
  service_end_hour2: string
  service_end_minute2: string
  service_start_hour3: string
  service_start_minute3: string
  service_end_hour3: string
  service_end_minute3: string
  attendance_start_hour: string
  attendance_start_minute: string
}

interface ServiceTimes {
  service_start_hour: string
  service_start_minute: string
  service_end_hour: string
  service_end_minute: string
}

interface ExtendedSupportAdditionTime1AndTime2 {
  extended_support_start_time1: string
  extended_support_end_time1: string
  extended_support_start_time2: string
  extended_support_end_time2: string
}

const getStandardSupportTime = (service_type, form_of_provision) => {
  if (service_type == 2) {
    // if cds then 5 hours
    return 5
  } else if (service_type == 1) {
    // if ads then 5 hours for school holiday
    if (form_of_provision == 2) {
      return 5
    }
    // else 3 hours
    return 3
  }
  return 0
}

export const getTimeDifferenceInMinutes = (
  startHour,
  startMinute,
  endHour,
  endMinute
): number => {
  const start_hour = +startHour
  const start_minute = +startMinute
  const end_hour = +endHour
  const end_minute = +endMinute
  if (
    isNaN(start_hour) ||
    isNaN(start_minute) ||
    isNaN(end_hour) ||
    isNaN(end_minute)
  ) {
    return 0
  }
  return end_hour * 60 + end_minute - (start_hour * 60 + start_minute)
}

const GetExtendedSupportAdditionTimeForNoServiceTimeOrNoOverlap = (
  props: IExtendedSupportAdditionTimeForNoServiceTimeOrNoOverlap
): ExtendedSupportAdditionTime => {
  const {
    attendance_start_hour,
    attendance_start_minute,
    attendance_end_hour,
    attendance_end_minute,
    standard_hours,
  } = props

  const response: ExtendedSupportAdditionTime = {
    has_extended_support_time1: true,
    extended_start_hour1: 0,
    extended_start_minute1: 0,
    extended_end_hour1: +attendance_end_hour,
    extended_end_minute1: +attendance_end_minute,
    has_extended_support_time2: false,
    extended_start_hour2: 0,
    extended_start_minute2: 0,
    extended_end_hour2: 0,
    extended_end_minute2: 0,
  }
  const inMinutes = standard_hours * 60
  const standard_hour = Math.floor(inMinutes / 60)
  const standard_minute = inMinutes % 60

  response.extended_start_hour1 = +attendance_start_hour + standard_hour
  response.extended_start_minute1 = +attendance_start_minute + standard_minute

  if (response.extended_start_minute1 >= 60) {
    response.extended_start_hour1++
    response.extended_start_minute1 = response.extended_start_minute1 % 60
  }

  return response
}

const extendedHoursOverlap = (props: IExtendedHourOverlap): boolean => {
  const {
    service_start_hour,
    service_start_minute,
    attendance_start_hour,
    attendance_start_minute,
    extended_hours,
  } = props

  const serviceStartHr = +service_start_hour
  const serviceStartMin = +service_start_minute
  const attendanceStartHr = +attendance_start_hour
  const attendanceStartMin = +attendance_start_minute

  if (
    isNaN(serviceStartHr) ||
    isNaN(serviceStartMin) ||
    isNaN(attendanceStartHr) ||
    isNaN(attendanceStartMin)
  ) {
    return false
  }

  const differenceInMinutes = getTimeDifferenceInMinutes(
    attendance_start_hour,
    attendance_start_minute,
    service_start_hour,
    service_start_minute
  )

  const differenceInHours = differenceInMinutes / 60

  return differenceInHours > 0 && differenceInHours <= extended_hours
}

export const GetExtendedSupportAdditionTime = (
  props: IExtendedSupportAdditionTime
): ExtendedSupportAdditionTime => {
  const response: ExtendedSupportAdditionTime = {
    has_extended_support_time1: false,
    extended_start_hour1: 0,
    extended_start_minute1: 0,
    extended_end_hour1: 0,
    extended_end_minute1: 0,
    has_extended_support_time2: false,
    extended_start_hour2: 0,
    extended_start_minute2: 0,
    extended_end_hour2: 0,
    extended_end_minute2: 0,
  }

  const {
    service_start_hour1,
    service_start_minute1,
    service_end_hour1,
    service_end_minute1,
    service_start_hour2,
    service_start_minute2,
    service_end_hour2,
    service_end_minute2,
    service_start_hour3,
    service_start_minute3,
    service_end_hour3,
    service_end_minute3,
    attendance_start_hour,
    attendance_start_minute,
    attendance_end_hour,
    attendance_end_minute,
    service_type,
    form_of_provision,
  } = props

  // if attendance time is not complete then no further calculation
  if (
    !attendance_start_hour ||
    !attendance_start_minute ||
    !attendance_end_hour ||
    !attendance_end_minute
  ) {
    return response
  }

  const suitableServiceProvision = getSuitableServiceProvisionTime({
    service_start_hour1,
    service_start_minute1,
    service_end_hour1,
    service_end_minute1,
    service_start_hour2,
    service_start_minute2,
    service_end_hour2,
    service_end_minute2,
    service_start_hour3,
    service_start_minute3,
    service_end_hour3,
    service_end_minute3,
    attendance_start_hour,
    attendance_start_minute,
  })

  const {
    service_start_hour,
    service_start_minute,
    service_end_hour,
    service_end_minute,
  } = suitableServiceProvision

  const standardHours = getStandardSupportTime(service_type, form_of_provision)
  const standardHoursInMinutes = standardHours * 60

  const usageMinutes = getTimeDifferenceInMinutes(
    attendance_start_hour,
    attendance_start_minute,
    attendance_end_hour,
    attendance_end_minute
  )

  const usageHour = usageMinutes / 60

  const exceededHours = usageHour - standardHours

  // usage doesn't exceed standard hours or it is less than 30 mins
  if (exceededHours < 0.5) {
    return response
  }

  // if there's no service time set or no overlap
  if (
    !service_start_hour ||
    !service_end_hour ||
    !service_start_minute ||
    !service_end_minute ||
    !extendedHoursOverlap({
      service_start_hour,
      service_start_minute,
      attendance_start_hour,
      attendance_start_minute,
      extended_hours: exceededHours,
    })
  ) {
    return GetExtendedSupportAdditionTimeForNoServiceTimeOrNoOverlap({
      attendance_start_hour,
      attendance_start_minute,
      attendance_end_hour,
      attendance_end_minute,
      standard_hours: standardHours,
    })
  }

  // extended time 1 = attendance start till service start time
  response.has_extended_support_time1 = true
  response.extended_start_hour1 = +attendance_start_hour
  response.extended_start_minute1 = +attendance_start_minute
  response.extended_end_hour1 = +service_start_hour
  response.extended_end_minute1 = +service_start_minute

  const extendedDurationInMinutes1 = getTimeDifferenceInMinutes(
    response.extended_start_hour1,
    response.extended_start_minute1,
    response.extended_end_hour1,
    response.extended_end_minute1
  )

  const remainingExtendedDuration =
    exceededHours * 60 - extendedDurationInMinutes1

  // extended time 2
  if (remainingExtendedDuration > 0) {
    let startHr =
      response.extended_end_hour1 + Math.floor(standardHoursInMinutes / 60)
    let startMin = response.extended_end_minute1 + (standardHoursInMinutes % 60)
    const endHr = +attendance_end_hour
    const endMin = +attendance_end_minute

    if (startMin >= 60) {
      startHr++
      startMin = response.extended_start_minute2 % 60
    }

    if (extendedDurationInMinutes1 <= 0) {
      // same start times so put it in addition 1
      response.has_extended_support_time1 = true
      response.extended_start_hour1 = startHr
      response.extended_start_minute1 = startMin
      response.extended_end_hour1 = endHr
      response.extended_end_minute1 = endMin
    } else {
      // extended time 2 = extended support time 1 end  + standard hours till attendance end time
      response.has_extended_support_time2 = true
      response.extended_start_hour2 = startHr
      response.extended_start_minute2 = startMin
      response.extended_end_hour2 = endHr
      response.extended_end_minute2 = endMin
    }
  }

  return response
}

export const CATEGORY_OPTIONS = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
]

export const CATEGORY_OPTIONS_AFTER_SCHOOL = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
]

export const CATEGORY_RADIO_OPTIONS = [
  {
    label: "Category 1 ",
    value: 1,
  },
  {
    label: "Category 2",
    value: 2,
  },
  {
    label: "Category 3",
    value: 3,
  },
]

export const CATEGORY_RADIO_OPTIONS_AFTER_SCHOOL = [
  {
    label: "Category 1 ",
    value: 1,
  },
  {
    label: "Category 2",
    value: 2,
  },
]

export const GetCategoryByNumberOfMinutes = (
  totalMins: number,
  isAfterSchoolChild: boolean
): number => {
  let category = 0

  if (isAfterSchoolChild) {
    if (totalMins >= 30 && totalMins <= 90) {
      category = 1
    } else if (totalMins > 90) {
      category = 2
    }
  } else {
    if (totalMins >= 30 && totalMins <= 90) {
      category = 1
    } else if (totalMins > 90 && totalMins <= 180) {
      category = 2
    } else if (totalMins > 180) {
      category = 3
    }
  }

  return category
}

const convertUsageTimeToNumberOfHours = (totalMinutes: number): number => {
  const hr = Math.floor(totalMinutes / 60)
  const min = totalMinutes % 60

  if (hr == 0 && min < 30) {
    return 0
  }

  let calculationHour = hr

  if (min >= 1 && min <= 30) {
    calculationHour += 0.5
  } else if (min > 30) {
    calculationHour++
  }

  return calculationHour
}

export const IsAfterSchoolChild = (service_type, form_of_provision) => {
  return service_type == 1 && form_of_provision != 2
}

export const getHoursAndCategory = (
  start_hour,
  start_min,
  end_hour,
  end_min,
  isAfterSchoolChild
): IHoursAndCategory => {
  const response: IHoursAndCategory = {
    hours: 0,
    minutes: 0,
    category: 0,
    number_of_hours: 0,
  }
  const differenceInMinutes = getTimeDifferenceInMinutes(
    start_hour,
    start_min,
    end_hour,
    end_min
  )

  if (differenceInMinutes <= 0) {
    return response
  }

  response.category = GetCategoryByNumberOfMinutes(
    differenceInMinutes,
    isAfterSchoolChild
  )

  response.hours = Math.floor(differenceInMinutes / 60)
  response.minutes = differenceInMinutes % 60

  response.number_of_hours =
    convertUsageTimeToNumberOfHours(differenceInMinutes)

  return response
}

export const AUTOMATIC_MANUAL_ATTACH_VALUES = {
  AUTOMATIC: 1,
  MANUAL: 2,
  ATTACH_TABLE: 3,
}

export const AUTOMATIC_MANUAL_OPTIONS = [
  {
    label: "Calculate Automatically",
    value: AUTOMATIC_MANUAL_ATTACH_VALUES.AUTOMATIC,
  },
  {
    label: "Calculate manually",
    value: AUTOMATIC_MANUAL_ATTACH_VALUES.MANUAL,
  },
]

export const ATTACH_TABLE_OPTION = [
  {
    label: "Apply setting from attached table",
    value: AUTOMATIC_MANUAL_ATTACH_VALUES.ATTACH_TABLE,
  },
]

// 3 possible service provision times
// get one that starts before attendance time within the service provision period
export const getSuitableServiceProvisionTime = (
  props: ISuitableServiceProvisionTime
): ServiceTimes => {
  const {
    service_start_hour1,
    service_start_minute1,
    service_end_hour1,
    service_end_minute1,
    service_start_hour2,
    service_start_minute2,
    service_end_hour2,
    service_end_minute2,
    service_start_hour3,
    service_start_minute3,
    service_end_hour3,
    service_end_minute3,
    attendance_start_hour,
    attendance_start_minute,
  } = props

  const IsBeforeOrWithinRange = (
    startHour,
    startMin,
    endHour,
    endMin
  ): boolean => {
    const startTime = +startHour + +startMin / 60

    // service start time is same or before attendance time
    if (startTime >= attendanceTime) {
      return true
    }

    const endTime = +endHour + +endMin / 60

    // attendance time is within service time
    if (startTime <= attendanceTime && endTime >= attendanceTime) {
      return true
    }

    return false
  }

  const response: ServiceTimes = {
    service_start_hour: service_start_hour1,
    service_start_minute: service_start_minute1,
    service_end_hour: service_end_hour1,
    service_end_minute: service_end_minute1,
  }

  // no attendance time exit
  if (!attendance_start_hour && !attendance_start_minute) {
    return response
  }

  const attendanceTime = +attendance_start_hour + +attendance_start_minute / 60

  if (
    IsBeforeOrWithinRange(
      service_start_hour1,
      service_start_minute1,
      service_end_hour1,
      service_end_minute1
    )
  ) {
    return response
  } else if (
    IsBeforeOrWithinRange(
      service_start_hour2,
      service_start_minute2,
      service_end_hour2,
      service_end_minute2
    )
  ) {
    response.service_start_hour = service_start_hour2
    response.service_start_minute = service_start_minute2
    response.service_end_hour = service_end_hour2
    response.service_end_minute = service_end_minute2
  } else if (
    IsBeforeOrWithinRange(
      service_start_hour3,
      service_start_minute3,
      service_end_hour3,
      service_end_minute3
    )
  ) {
    response.service_start_hour = service_start_hour3
    response.service_start_minute = service_start_minute3
    response.service_end_hour = service_end_hour3
    response.service_end_minute = service_end_minute3
  }

  return response
}

export const getExtendedSupportTime1AndTime2 = (
  props: ExtendedSupportAdditionTime1AndTime2
): ExtendedSupportAdditionTime1AndTime2 => {
  const {
    extended_support_start_time1,
    extended_support_end_time1,
    extended_support_start_time2,
    extended_support_end_time2,
  } = props
  const response: ExtendedSupportAdditionTime1AndTime2 = {
    extended_support_start_time1: "",
    extended_support_end_time1: "",
    extended_support_start_time2: "",
    extended_support_end_time2: "",
  }

  if (extended_support_start_time1 && extended_support_end_time1) {
    // set time 1 if available
    response.extended_support_start_time1 = extended_support_start_time1
    response.extended_support_end_time1 = extended_support_end_time1
    if (extended_support_start_time2 && extended_support_end_time2) {
      // set time 2 if available
      response.extended_support_start_time2 = extended_support_start_time2
      response.extended_support_end_time2 = extended_support_end_time2
    }
  } else {
    // if there's no time 1 but there's time 2 then set it to time 1
    if (extended_support_start_time2 && extended_support_end_time2) {
      response.extended_support_start_time1 = extended_support_start_time2
      response.extended_support_end_time1 = extended_support_end_time2
    }
  }

  return response
}
