import { dynamicLangString } from "@project/shared"
import { notification, Skeleton } from "antd"
import moment from "moment"
import { useRouter } from "next/router"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import styled from "styled-components"
import { getAllStaffList } from "../../../services/attendanceSchedule"
import {
  fetchBulkChildAttendance,
  saveBulkChildAttendance,
} from "../../../services/attendanceTable"
import {
  UserAttendanceBulkEditInfoTable,
  UserAttendanceBulkEditOperation,
} from "../../molecules"
import { IDisplayOptions } from "../../molecules/UserAttendanceBulkEditOperation"
import {
  CATEGORY_OPTIONS,
  CATEGORY_OPTIONS_AFTER_SCHOOL,
  IsAfterSchoolChild,
} from "../../../utils/attendance"

const Container = styled.div`
  margin: 20px 0;
`
interface IBulkUserAttendanceEditPage {
  defaultDisplayOptions: IDisplayOptions
}

export const BulkUserAttendanceEditPage = ({
  defaultDisplayOptions,
}: IBulkUserAttendanceEditPage) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { isLoading: staffListLoading, data: staffList } = useQuery(
    ["staffList"],
    async () => {
      const staffData = await getAllStaffList()
      const staffOptions = []
      if (staffData.data) {
        staffData.data.map((staff) => {
          const staffObj = {
            key: staff?.id,
            label: staff?.staff_name,
            value: staff?.id,
            furigana_name: staff?.staff_name_furiganaame,
          }
          staffOptions.push(staffObj)
        })
      }
      return staffOptions
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
    }
  )

  const [displayParams, setDisplayParams] = useState(defaultDisplayOptions)
  const [userAttendanceData, setUserAttendanceData] = useState([])
  const [bulkOptions, setBulkOptions] = useState({})

  const { data: attendanceData, isLoading } = useQuery(
    ["getUserAttendanceForDay", displayParams],
    async () => {
      const query = []
      if (displayParams.facilityIds.length)
        query.push("facility_id=" + displayParams.facilityIds.join(","))
      if (displayParams.date) query.push("date=" + displayParams.date)
      if (displayParams.services.length)
        query.push("user_service_id=" + displayParams.services.join(","))
      const { data } = await fetchBulkChildAttendance(query.join("&"))
      const attendance_data = data?.attendance_data?.map((aData) => {
        const child_actual_cost_burden = aData?.actual_costs?.map((aCost) => {
          const actualCostBurden = aData?.child_actual_cost_burden?.find(
            (acb) => acb?.actual_cost_id == aCost?.id
          )
          return {
            actual_cost_burden_user_record_id:
              actualCostBurden?.actual_cost_burden_user_record_id,
            actual_cost_id: aCost?.id,
            actual_cost_name: aCost?.actual_cost_name,
            actual_cost_price:
              actualCostBurden?.actual_cost_price || aCost?.price,
            child_id: aData?.child_id,
            date: aData?.date?.split("T")?.[0],
            facility_id: aData?.facility_id,
            used_flag: actualCostBurden ? true : false,
          }
        })

        const hasExtendedAddition = !!aData?.extended_addition?.id

        let extended_support_addition_start_hour1,
          extended_support_addition_start_minute1,
          extended_support_addition_end_hour1,
          extended_support_addition_end_minute1,
          extended_support_addition_start_hour2,
          extended_support_addition_start_minute2,
          extended_support_addition_end_hour2,
          extended_support_addition_end_minute2 = null

        if (hasExtendedAddition) {
          const startTime = aData?.extended_addition?.start_time?.split(":")

          if (startTime?.length > 1) {
            extended_support_addition_start_hour1 = startTime[0]
            extended_support_addition_start_minute1 = startTime[1]
          }

          const endTime = aData?.extended_addition?.end_time?.split(":")

          if (endTime?.length > 1) {
            extended_support_addition_end_hour1 = endTime[0]
            extended_support_addition_end_minute1 = endTime[1]
          }

          const startTime2 = aData?.extended_addition?.start_time_2?.split(":")

          if (startTime2?.length > 1) {
            extended_support_addition_start_hour2 = startTime2[0]
            extended_support_addition_start_minute2 = startTime2[1]
          }

          const endTime2 = aData?.extended_addition?.end_time_2?.split(":")

          if (endTime2?.length > 1) {
            extended_support_addition_end_hour2 = endTime2[0]
            extended_support_addition_end_minute2 = endTime2[1]
          }
        }

        const category_options = IsAfterSchoolChild(
          aData?.user_service_type,
          aData?.service_type
        )
          ? CATEGORY_OPTIONS_AFTER_SCHOOL
          : CATEGORY_OPTIONS

        return {
          ...aData,
          entry_hour: aData?.entry_time
            ? aData?.entry_time?.split(":")?.[0]
            : null,
          entry_minute: aData?.entry_time
            ? aData?.entry_time?.split(":")?.[1]
            : null,
          out_hour: aData?.out_time ? aData?.out_time?.split(":")?.[0] : null,
          out_minute: aData?.out_time ? aData?.out_time?.split(":")?.[1] : null,
          temperature1: aData?.temperature
            ? aData?.temperature?.toString()?.split(".")?.[0]
            : null,
          temperature2: aData?.temperature
            ? aData?.temperature?.toString()?.split(".")?.[1]
              ? aData?.temperature?.toString()?.split(".")?.[1]
              : aData?.temperature?.toString()?.split(".")?.[0]
              ? "00"
              : null
            : null,
          save_staff_id: aData?.save_staff_id ? aData?.save_staff_id : null,
          absence_reason: aData?.absence_reason ? aData?.absence_reason : null,
          child_actual_cost_burden,
          next_scheduled_date:
            aData?.next_scheduled_date &&
            moment(aData?.next_scheduled_date).isValid()
              ? moment(aData?.next_scheduled_date).format("MM月DD日")
              : "",
          extended_option: hasExtendedAddition ? 1 : 0,
          extended_support_addition_start_hour1,
          extended_support_addition_start_minute1,
          extended_support_addition_end_hour1,
          extended_support_addition_end_minute1,
          extended_support_addition_start_hour2,
          extended_support_addition_start_minute2,
          extended_support_addition_end_hour2,
          extended_support_addition_end_minute2,
          category_options,
        }
      })

      setUserAttendanceData(attendance_data)
      return {
        attendance_stats: data?.attendance_stats,
        attendance_data: data?.attendance_data,
      }
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
    }
  )

  const getDayAttendanceLink = () => {
    return `/user-attendance-table/day-list?date=${
      displayParams?.date
    }&facilityId=${displayParams?.facilityIds?.join(",")}`
  }

  const { mutate: saveBulkAttendance, isLoading: isSaving } = useMutation(
    saveBulkChildAttendance,
    {
      onSuccess: () => {
        notification.success({
          message: dynamicLangString(["Attendance", "Updated Successfully"]),
        })
        router.push(getDayAttendanceLink())
      },
      onError: () => {
        notification.error({
          message: t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  const handleDisplayChange = (values) => {
    setDisplayParams(values)
    const queries = []
    Object.keys(values).map((key) => {
      if (Array.isArray(values[key])) {
        if (values[key].length) queries.push(`${key}=${values[key].join(",")}`)
      } else if (values[key]) {
        queries.push(`${key}=${values[key]}`)
      }
    })
    router.push("?" + queries.join("&"), undefined, { shallow: true })
  }

  const handleBulkChange = (values) => {
    setBulkOptions({ ...values, reset: false })
  }

  const handleReset = () => {
    setBulkOptions({ ...bulkOptions, reset: true })
  }

  const onDayChange = (type: "yesterday" | "today" | "tomorrow") => {
    let newDate = moment(displayParams.date)
    switch (type) {
      case "yesterday":
        newDate = newDate.subtract(1, "day")
        break
      case "today":
        newDate = moment()
        break
      case "tomorrow":
        newDate = newDate.add(1, "day")
    }
    const newDisplayOptions = {
      ...displayParams,
      date: newDate.format("YYYY-MM-DD"),
    }
    handleDisplayChange(newDisplayOptions)
  }

  return (
    <Container>
      <UserAttendanceBulkEditOperation
        defaultDisplayOptions={displayParams}
        onDisplayChange={handleDisplayChange}
        onBulkReflect={handleBulkChange}
        onReset={handleReset}
        staffList={staffList}
      />
      {isLoading || staffListLoading ? (
        <Skeleton />
      ) : (
        <UserAttendanceBulkEditInfoTable
          date={displayParams.date}
          handlePagination={onDayChange}
          stats={attendanceData?.attendance_stats ?? {}}
          attendanceData={userAttendanceData}
          updateUserAttendance={setUserAttendanceData}
          bulkOptions={bulkOptions}
          staffList={staffList}
          handleSave={saveBulkAttendance}
          cancelRoute={getDayAttendanceLink()}
          isSaving={isSaving}
        />
      )}
    </Container>
  )
}
