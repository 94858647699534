import { useContext } from "react"
import { useRouter } from "next/router"

// packages
import { t } from "i18next"

// shared
import {
  AFTER_DAY_SCHOOL_REVENUE_CONDITION_1,
  ALL_FACILITY_KINDS,
  CHILD_DEVELOPMENT_SUPPORT_REVENUE_CONDITION_1,
  CHILD_DEVELOPMENT_SUPPORT_REVENUE_CONDITION_2,
  GRADES,
} from "@project/shared"

// utils
import { AuthContext } from "../../../../utils"

// types
import { FormFieldsProps } from "../../../molecules/FormFields/types"
import {
  FacilityStatusFieldSchemaProps,
  FacilityTypeFormProps,
  FormType,
} from "../types"
import {
  getAbusePreventionNotImplementedSubtraction,
  getBusinessContinuityPlanNotImplementedSubtraction,
  getInformationDisclosureNotReportedSubtraction,
  getSupportProgramNotPublicizedSubtraction,
  getAdditionalAllowance,
  getProfessionalSupport,
  getProfessionalSupportSystemAddition,
  getCoreFunctionEnhancementAddition,
  getWelfareCareStaffTreatmentImprovement,
  getCoreFunctionEnhancementSiteAddition,
  getWelfareTreatmentImprovement,
  getSpecialTreatmentImprovement,
  getWelfareBaseUp,
  getSupportCategories,
} from "./version"
import moment from "moment"
import { ServiceType } from "./version/types"
import { getLinkAdmissionRecordsAndSpecialSupportAdditionRecords } from "./version/linkAdmissionRecordsAndSpecialSupportAdditionRecords"

export const facilityTypeForm = ({
  formik,
  date,
}: FacilityTypeFormProps): FormFieldsProps => {
  const { facilities } = useContext(AuthContext)
  const router = useRouter()
  const { id } = router.query

  return [
    {
      fieldLabel: "Type",
      required: true,
      isRowFlexed: true,
      fields: [
        {
          name: "facility_type_id",
          fieldType: {
            type: "dropdown",
            extraOptions: { option: ALL_FACILITY_KINDS },
          },
          width: 300,
        },

        {
          fieldType: {
            type: "button",
            extraOptions: {
              text: t("Click here to set business and service hours"),
              onClick: () => {
                window.open(
                  `/business-day-registration?facilityId=${
                    formik.values?.facility_id
                  }&year=${date.year}&month=${date.month}&facility_name=${
                    facilities?.find((f) => f.id === Number(id))
                      ?.facility_name_short
                  }`,
                  "_blank"
                )
              },
              type: "button",
              typeOf: "primary",
            },
          },
          className: "business-service-hrs-btn",
        },
        {
          fieldType: { type: "checkbox" },
          label: "Special exceptions for multi-functional types are applied",
          name: "multi_facility_type_flg",
        },
      ],
    },
    {
      fieldLabel: "Type1 (Condition 1)",
      isRowFlexed: true,
      required: true,
      fields: [
        {
          fieldType: {
            type: "dropdown",
            extraOptions: {
              option: [1, 3].includes(formik.values?.facility_type_id)
                ? AFTER_DAY_SCHOOL_REVENUE_CONDITION_1
                : CHILD_DEVELOPMENT_SUPPORT_REVENUE_CONDITION_1,
              initialValue: formik.values.type1_condition1,
            },
          },
          className: "facility-type-2",
          name: "type1_condition1",
          width: 300,
        },
      ],
    },
    [2, 4].includes(formik.values?.facility_type_id) &&
    formik.values.type1_condition1 == 4
      ? {
          fieldLabel: "Type1 (Condition 2)",
          isRowFlexed: true,
          required: true,
          fields: [
            {
              fieldType: {
                type: "dropdown",
                extraOptions: {
                  option: CHILD_DEVELOPMENT_SUPPORT_REVENUE_CONDITION_2,
                  initialValue: formik.values.type1_condition2,
                },
              },
              className: "facility-type-2",
              name: "type1_condition2",
              width: 300,
            },
          ],
        }
      : null,
    formik.values.multi_facility_type_flg
      ? {
          fieldLabel: "Type 2",
          isRowFlexed: true,
          required: formik.values?.multi_facility_type_flg,
          fields: [
            {
              fieldType: {
                type: "dropdown",
                extraOptions: {
                  option: ALL_FACILITY_KINDS,
                  initialValue: formik.values.facility_type_id_2 || null,
                },
              },
              className: "facility-type-2",
              name: "facility_type_id_2",
              width: 300,
            },
          ],
        }
      : null,
    formik.values.multi_facility_type_flg
      ? {
          fieldLabel: "Type 2 (Condition 1)",
          isRowFlexed: true,
          required: formik.values?.multi_facility_type_flg,
          fields: [
            {
              fieldType: {
                type: "dropdown",
                extraOptions: {
                  option: [1, 3].includes(formik.values?.facility_type_id_2)
                    ? AFTER_DAY_SCHOOL_REVENUE_CONDITION_1
                    : CHILD_DEVELOPMENT_SUPPORT_REVENUE_CONDITION_1,
                  initialValue: formik.values.type2_condition1,
                },
              },
              className: "facility-type-2",
              name: "type2_condition1",
              width: 300,
            },
          ],
        }
      : null,
    formik.values.multi_facility_type_flg &&
    [2, 4].includes(formik.values.facility_type_id_2) &&
    formik.values.type2_condition1 == 4
      ? {
          fieldLabel: "Type 2 (Condition 2)",
          isRowFlexed: true,
          required: formik.values?.multi_facility_type_flg,
          fields: [
            {
              fieldType: {
                type: "dropdown",
                extraOptions: {
                  option: CHILD_DEVELOPMENT_SUPPORT_REVENUE_CONDITION_2,
                  initialValue: formik.values.type2_condition2,
                },
              },
              className: "facility-type-2",
              name: "type2_condition2",
              width: 300,
            },
          ],
        }
      : null,

    {
      fieldLabel: "Type (visit support for nursery schools, etc.)",
      isRowFlexed: true,
      fields: [
        {
          name: "typeSupport",
          fieldType: { type: "checkbox" },
          label: t("Provide"),
        },
      ],
    },
  ]
}

export const facilityStatusForm = ({
  checkServiceType,
  service,
  handleCapacityChange,
  formik,
  type,
  showInAfterFirstForm,
  getErrorClass,
}: FacilityStatusFieldSchemaProps): FormFieldsProps => {
  const date = moment({
    year: Number(formik.values.year),
    month: Number(formik.values.month) - 1,
  })

  const conditions = {
    [FormType.FIRST]: Number(formik.values.type1_condition1),
    [FormType.SECOND]: Number(formik.values.type2_condition1),
  }

  const serviceType = showInAfterFirstForm
    ? ServiceType.AFTER_SCHOOL_DAY_SERVICE
    : ServiceType.CHILD_DEVELOPMENT_SUPPORT

  return [
    [1, 2].includes(service) &&
      getSupportCategories(date, {
        name:
          type === FormType.FIRST ? "supportCategories" : "supportCategories2",
        serviceType,
      }),
    {
      fieldLabel: "Exceed capacity",
      isRowFlexed: true,
      isShown: true,
      fields: [
        {
          name: type === FormType.FIRST ? "exceedCapacity" : "exceedCapacity2",
          fieldType: {
            type: "radio",
            extraOptions: {
              option: [
                {
                  label: t("No"),
                  value: "0",
                },
                {
                  label: t("Yes"),
                  value: "1",
                },
              ],
            },
          },
        },
      ],
    },
    [1, 2].includes(service) && {
      fieldLabel: "Lack of Service Employee Subtraction",
      isRowFlexed: true,
      isShown: true,
      fields: [
        {
          name: type === FormType.FIRST ? "lackService" : "lackService2",
          fieldType: {
            type: "radio",
            extraOptions: {
              option: [
                {
                  label: t("No"),
                  value: "0",
                },
                {
                  label: t("Up to 2 months"),
                  value: "1",
                },
                {
                  label: t("After 3 months"),
                  value: "2",
                },
              ],
            },
          },
        },
      ],
    },
    {
      fieldLabel: "Unpublished subtraction of self-evaluation results",
      isRowFlexed: true,
      isShown: true,
      fields: [
        {
          name:
            type === FormType.FIRST
              ? "unpublishedSubtraction"
              : "unpublishedSubtraction2",
          fieldType: {
            type: "radio",
            extraOptions: {
              option: [
                {
                  label: t("No"),
                  value: "0",
                },
                {
                  label: t("Yes"),
                  value: "1",
                },
              ],
            },
          },
        },
      ],
    },
    [1, 2].includes(service) && {
      fieldLabel: "Lack of child development support manager subtraction",
      isRowFlexed: true,
      isShown: true,
      fields: [
        {
          name:
            type === FormType.FIRST
              ? "lackSupportManager"
              : "lackSupportManager2",
          fieldType: {
            type: "radio",
            extraOptions: {
              option: [
                {
                  label: t("No"),
                  value: "0",
                },
                {
                  label: t("Up to 4 months"),
                  value: "1",
                },
                {
                  label: t("After 5 months"),
                  value: "2",
                },
              ],
            },
          },
        },
      ],
    },
    {
      fieldLabel: "Physical restraint abolition non-implementation subtraction",
      isRowFlexed: true,
      isShown: true,
      fields: [
        {
          name:
            type === FormType.FIRST
              ? "physicalRestraintSubtraction"
              : "physicalRestraintSubtraction2",
          fieldType: {
            type: "radio",
            extraOptions: {
              option: [
                {
                  label: t("No"),
                  value: "0",
                },
                {
                  label: t("Yes"),
                  value: "1",
                },
              ],
            },
          },
        },
      ],
    },
    {
      fieldLabel: "Subtraction of opening time",
      isRowFlexed: true,
      isShown: true,
      fields: [
        {
          name:
            type === FormType.FIRST
              ? "subtractionOpeningTime"
              : "subtractionOpeningTime2",
          fieldType: {
            type: "radio",
            extraOptions: {
              option: [
                {
                  label: t("No"),
                  value: "0",
                },
                {
                  label: t("Below 4 hrs"),
                  value: "1",
                },
                {
                  label: t("More than 4 hrs less than 6 hrs"),
                  value: "2",
                },
              ],
            },
          },
        },
      ],
    },
    getAbusePreventionNotImplementedSubtraction(date, {
      name:
        type === FormType.FIRST
          ? "abusePreventionNotImplementedSubtraction"
          : "abusePreventionNotImplementedSubtraction2",
    }),
    getBusinessContinuityPlanNotImplementedSubtraction(date, {
      name:
        type === FormType.FIRST
          ? "businessContinuityPlanNotImplementedSubtraction"
          : "businessContinuityPlanNotImplementedSubtraction2",
    }),
    getInformationDisclosureNotReportedSubtraction(date, {
      name:
        type === FormType.FIRST
          ? "informationDisclosureNotReportedSubtraction"
          : "informationDisclosureNotReportedSubtraction2",
    }),
    getSupportProgramNotPublicizedSubtraction(date, {
      name:
        type === FormType.FIRST
          ? "supportProgramNotPublicizedSubtraction"
          : "supportProgramNotPublicizedSubtraction2",
    }),
    getAdditionalAllowance(date, {
      name:
        type === FormType.FIRST
          ? "additionalAllowance"
          : "additionalAllowance2",
    }),
    [3, 4].includes(service) && {
      fieldLabel: "Nursing staff additional allowance",
      isRowFlexed: true,
      isShown: true,
      fields: [
        {
          name:
            type === FormType.FIRST
              ? "nursing_allowance"
              : "nursing_allowance_2",
          fieldType: {
            type: "radio",
            extraOptions: {
              option: [
                {
                  label: t("No"),
                  value: "0",
                },
                {
                  label: t("I"),
                  value: "1",
                },
                {
                  label: t("II"),
                  value: "2",
                },
              ],
            },
          },
        },
      ],
    },
    checkServiceType == 1 && {
      fieldLabel:
        "Additions for Child Instructors, etc. (Child Instructors, etc.)",
      isRowFlexed: true,
      fields: [
        {
          name:
            type === FormType.FIRST
              ? "additionalChildInstructorAfterSchool"
              : "additionalChildInstructorAfterSchool2",
          fieldType: {
            type: "radio",
            extraOptions: {
              option: [
                {
                  label: t("No"),
                  value: "0",
                },
                {
                  label: t("Division 1 (3 hours or more)"),
                  value: "1",
                },
                {
                  label: t("Division 2 (less than 3 hours)"),
                  value: "2",
                },
              ],
            },
          },
        },
      ],
    },
    getProfessionalSupport(date, {
      name:
        type === FormType.FIRST
          ? "professionalSupport"
          : "professionalSupport2",
      showInAfterFirstForm,
    }),
    getProfessionalSupportSystemAddition(date, {
      name:
        type === FormType.FIRST
          ? "professionalSupportSystemAddition"
          : "professionalSupportSystemAddition2",
    }),
    {
      fieldLabel: "Assignment of Welfare Professionals",
      isRowFlexed: true,
      isShown: true,
      fields: [
        {
          name:
            type === FormType.FIRST
              ? "welfareProfessionals"
              : "welfareProfessionals2",
          fieldType: {
            type: "radio",
            extraOptions: {
              option: [
                {
                  label: t("No"),
                  value: "0",
                },
                {
                  label: t("I"),
                  value: "1",
                },
                {
                  label: t("II"),
                  value: "2",
                },
                {
                  label: t("III"),
                  value: "3",
                },
              ],
            },
          },
        },
      ],
    },
    getWelfareTreatmentImprovement(date, {
      name:
        type === FormType.FIRST
          ? "welfareTreatmentImprovement"
          : "welfareTreatmentImprovement2",
    }),
    getSpecialTreatmentImprovement(date, {
      name:
        type === FormType.FIRST
          ? "specialTreatmentImprovement"
          : "specialTreatmentImprovement2",
    }),
    getWelfareBaseUp(date, {
      name: type === FormType.FIRST ? "welfareBaseUp" : "welfareBaseUp2",
    }),
    getWelfareCareStaffTreatmentImprovement(date, {
      name:
        type === FormType.FIRST
          ? "welfareCareStaffTreatmentImprovement"
          : "welfareCareStaffTreatmentImprovement2",
    }),
    getCoreFunctionEnhancementAddition(date, {
      name:
        type === FormType.FIRST
          ? "coreFunctionEnhancementAddition"
          : "coreFunctionEnhancementAddition2",
      service,
      condition: conditions[type],
    }),
    getCoreFunctionEnhancementSiteAddition(date, {
      name:
        type === FormType.FIRST
          ? "coreFunctionEnhancementSiteAddition"
          : "coreFunctionEnhancementSiteAddition2",
      service,
      condition: conditions[type],
    }),
    {
      fieldLabel: "Capacity",
      isRowFlexed: true,
      required: true,
      isShown: true,
      fields: [
        {
          name: type === FormType.FIRST ? "max_capacity" : "max_capacity_2",
          fieldType: {
            type: "number",
            extraOptions: {
              label: "人 (Within 3 numbers)",
              labelAlignment: "right",
              min: 0,
              max: 999,
              onChange: (e) =>
                handleCapacityChange(
                  e,
                  type === FormType.FIRST ? "max_capacity" : "max_capacity_2"
                ),
            },
          },
          className: getErrorClass(
            type === FormType.FIRST ? "max_capacity" : "max_capacity_2",
            "input"
          ),
          width: 80,
        },
      ],
    },
    // breakpoint
    {
      fieldLabel: "Class grade",
      isRowFlexed: true,
      isShown: true,
      fields: [
        {
          name: type === FormType.FIRST ? "class_grade" : "class_grade_2",
          fieldType: {
            type: "dropdown",
            extraOptions: {
              option: GRADES,
              initialValue: formik.values.class_grade,
            },
          },
          className: getErrorClass(
            type === FormType.FIRST ? "class_grade" : "class_grade_2",
            "input"
          ),
          width: 300,
        },
      ],
    },
    {
      fieldLabel: "Transportation",
      isRowFlexed: true,
      isShown: true,
      fields: [
        {
          name: type === FormType.FIRST ? "pickup" : "pickup2",
          fieldType: {
            type: "radio",
            extraOptions: {
              option: [
                {
                  label: t("No"),
                  value: "0",
                },
                {
                  label: t("Yes"),
                  value: "1",
                },
              ],
              onChange: (e) => {
                formik.setFieldValue(
                  type === FormType.FIRST ? "pickup" : "pickup2",
                  e.target.value
                )
                if (e.target.value != 1) {
                  formik.setFieldValue("linkEntryExit", [])
                }
              },
            },
          },
        },
      ],
    },

    // {
    //   fieldLabel: "Upper limit management addition",
    //   isRowFlexed: true,
    //   isShown: true,
    //   fields: [
    //     {
    //       name:
    //         type === FormType.FIRST
    //           ? "upper_limit_management_additions"
    //           : "upper_limit_management_additions_2",
    //       fieldType: {
    //         type: "radio",
    //         extraOptions: {
    //           option: [
    //             {
    //               label: t("No"),
    //               value: "0",
    //             },
    //             {
    //               label: t("Yes"),
    //               value: "1",
    //             },
    //           ],
    //         },
    //       },
    //     },
    //   ],
    // },
    // 入室の記録と特別支援加算の記録を連動する
    getLinkAdmissionRecordsAndSpecialSupportAdditionRecords(date, {
      name:
        type === FormType.FIRST
          ? "link_admission_and_support"
          : "link_admission_and_support_2",
    }),
    {
      fieldLabel: t("Link entry/exit records with transportation records"),
      isRowFlexed: true,
      isShown:
        type === FormType.FIRST
          ? formik?.values?.pickup === "1"
          : formik?.values?.pickup2 === "1",
      fields: [
        {
          name:
            type === FormType.FIRST
              ? "link_attendance_transport"
              : "link_attendance_transport_2",
          label: t("Link"),
          fieldType: {
            type: "checkbox",
          },
        },
      ],
    },
    {
      fieldLabel: t(
        "Link the record of admission and the record of support addition for children with severe behavioral disorder."
      ),
      isRowFlexed: true,
      isShown: true,
      fields: [
        {
          name:
            type === FormType.FIRST
              ? "link_sever_behaviour_support_addition"
              : "link_sever_behaviour_support_addition_2",
          label: t("Link"),
          fieldType: {
            type: "checkbox",
          },
        },
      ],
    },
  ]
}

export const ADDITIONAL_ALLOWANCE_FOR_CHILD_INSTRUCTORS_OPTION = [
  // TODO: translate
  {
    label: "常勤専従・経験５年以上",
    value: "11",
  },
  {
    label: "常勤専従・経験５年未満",
    value: "12",
  },
  {
    label: "常勤換算・経験５年以上",
    value: "13",
  },
  {
    label: "常勤換算・経験５年未満",
    value: "14",
  },
  {
    label: t("Other workers"),
    value: "3",
  },
]
