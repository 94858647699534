// styled components
import { Container } from "./styles"

// components
import {
  BackBtn,
  FilterOperationBlock,
  FacilityChildListBlock,
  PrintFilterBlock,
} from "./components"

// types
import {
  FacilityChildListBlockProps,
  NationalInsuranceBillingFormPageProps,
} from "./types"
import moment from "moment"
import {
  ICSVDownloadProps,
  getNHIFCSVLink,
  saveUpperLimitDivision as updateUpperLimitDivision,
} from "../../../services/revenueManagement"
import { useMutation } from "react-query"
import { notification } from "antd"
import { useTranslation } from "react-i18next"
import { FACILITY_BILLING_METHOD } from "../../../utils"
import router from "next/router"
import { useEffect, useState } from "react"
import { PRINT_STYLE_OPTIONS } from "./constants"
import { FacilityChildListBlock2024 } from "./components/FacilityChildListBlock2024"

export const NationalInsuranceBillingFormPage: React.FC<
  NationalInsuranceBillingFormPageProps
> = ({
  facilityOptions,
  currentSearchQueries,
  handleCurrentSearchQueriesChange,
  currentBillingMethod,
  isBillingInfoLoading = false,
  isUserFeeConfirmed = false,
}) => {
  const { t } = useTranslation()
  const [isPrintPage, setIsPrintPage] = useState(false)
  const [printFilter, setPrintFilter] = useState({
    configuration: [],
    style: PRINT_STYLE_OPTIONS?.[0]?.value,
  })
  const currentBillingMethodName = currentBillingMethod
    ? FACILITY_BILLING_METHOD?.[currentBillingMethod]
    : ""

  const togglePrintPage = () => setIsPrintPage(!isPrintPage)

  useEffect(() => {
    window.scrollTo({
      top: 0,
    })
  }, [isPrintPage])

  const date = moment(
    `${currentSearchQueries?.year}-${currentSearchQueries?.month}-1`
  ).format("YYYY-MM-DD")

  const { year, month } = currentSearchQueries ?? {}

  const { mutate: getNHIFCSVDownloadLink, isLoading: isCSVDownloading } =
    useMutation(getNHIFCSVLink, {
      onSuccess: (response) => {
        const link = response?.data
        if (link) {
          const newLinkElement = document.createElement("a")
          newLinkElement.setAttribute("href", link)
          newLinkElement.setAttribute("target", "_blank")
          document.body.appendChild(newLinkElement)
          newLinkElement.click()
          newLinkElement.remove()
        }
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message || error?.data?.error
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    })

  const {
    mutate: saveUpperLimitDivision,
    isLoading: isSavingUpperLimitDivision,
  } = useMutation(updateUpperLimitDivision, {
    onSuccess: () => {
      notification.success({ message: t("Updated Successfully") })
    },
    onError: (error?: any) => {
      const msg = error?.data?.error?.message || error?.data?.error
      notification.error({
        message: msg
          ? t(msg)
          : t("Something went wrong. Please contact administrator."),
      })
    },
  })

  const handleNHIFCSVDownload = (
    csvFor: number,
    childIds: string,
    userBurdens?: string
  ) => {
    const payloads: ICSVDownloadProps = {
      year: currentSearchQueries?.year,
      month: currentSearchQueries?.month,
      facility_id: currentSearchQueries?.facility,
      service_type: facilityOptions?.find(
        (fac) => fac?.value == currentSearchQueries?.facility
      )?.type,
      receipt_option: +currentBillingMethod,
      csv_for: csvFor,
      child_ids: childIds,
    }

    if (userBurdens) {
      payloads.change_burden_max_amount_lists = userBurdens
    }

    if (payloads.receipt_option == 2) {
      // if reduced billing
      const { after_school } = router.query
      if (after_school?.toString() == "1") {
        // after school
        payloads.school_closure = 0
      } else {
        // school holiday
        payloads.school_closure = 1
      }
    }

    getNHIFCSVDownloadLink(payloads)
  }

  function handleNHIFCSVDownload2024(csvFor: number, childIds: string) {
    const { year, month, facility: facility_id } = currentSearchQueries
    const service_type = facilityOptions?.find(
      (fac) => String(fac?.value) === String(facility_id)
    )?.type
    const payloads: ICSVDownloadProps = {
      year,
      month,
      facility_id,
      service_type,
      receipt_option: +currentBillingMethod,
      csv_for: csvFor,
      child_ids: childIds,
    }

    // if (userBurdens) {
    //   payloads.change_burden_max_amount_lists = userBurdens
    // }

    if (payloads.receipt_option === 2) {
      // if reduced billing
      const { after_school } = router.query
      if (after_school?.toString() == "1") {
        // after school
        payloads.school_closure = 0
      } else {
        // school holiday
        payloads.school_closure = 1
      }
    }

    getNHIFCSVDownloadLink(payloads)
  }

  let csvDownloadCallback = handleNHIFCSVDownload
  if ((year >= 2024 && month >= 4) || year >= 2025) {
    csvDownloadCallback = handleNHIFCSVDownload2024
  }

  return (
    <Container>
      {!isPrintPage ? (
        <FilterOperationBlock
          currentDate={date}
          facilityOptions={facilityOptions}
          currentFacilityId={currentSearchQueries.facility}
          handleCurrentSearchQueriesChange={handleCurrentSearchQueriesChange}
        />
      ) : (
        <PrintFilterBlock
          initialState={printFilter}
          onDisplayChange={(filter) => setPrintFilter(filter)}
        />
      )}

      {!isPrintPage && (
        <BackBtn
          link={`/national-health-insurance-billing?facility_id=${currentSearchQueries?.facility}&year=${currentSearchQueries?.year}&month=${currentSearchQueries?.month}`}
        />
      )}

      <ListBlock
        year={year}
        month={month}
        isPrintPage={isPrintPage}
        togglePrintPage={togglePrintPage}
        currentDate={date}
        handleCSVDownload={csvDownloadCallback}
        currentBillingMethodName={t(currentBillingMethodName)}
        isBillingInfoLoading={isBillingInfoLoading}
        currentSearchQueries={currentSearchQueries}
        isCSVDownloading={isCSVDownloading}
        isUserFeeConfirmed={isUserFeeConfirmed}
        printFilter={printFilter}
        saveUpperLimitDivision={saveUpperLimitDivision}
        isSavingUpperLimitDivision={isSavingUpperLimitDivision}
      />
      {!isPrintPage && (
        <BackBtn
          link={`/national-health-insurance-billing?facility_id=${currentSearchQueries?.facility}&year=${currentSearchQueries?.year}&month=${currentSearchQueries?.month}`}
        />
      )}
    </Container>
  )
}

type ListBlockProps = FacilityChildListBlockProps & {
  year: number
  month: number
}

function ListBlock({ year, month, ...props }: ListBlockProps) {
  if ((year >= 2024 && month >= 4) || year >= 2025) {
    return <FacilityChildListBlock2024 {...props} />
  }
  return <FacilityChildListBlock {...props} />
}

export * from "./components"
